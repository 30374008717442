import React from "react";
import { CalendarProvider } from "../../components/Calendar/context";
import Calendar from "../../components/Calendar/calendar";
import { useBooking } from "../../components/Booking/bookingContext";

function Home() {
  const { setStep } = useBooking();

    setStep(0);

  return (
    <>
        <CalendarProvider>
            <Calendar />
        </CalendarProvider>
    </>
  );
}

export default Home;
