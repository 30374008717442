import React from "react";

import * as WetwheelsApi from "../../../../client-swagger/api";

import { useApp } from "../../../../AppContext";
import { ApiResponse } from "../../../../client-swagger/api";
import User from "../../../../enums/User";

const HandleBookingDecision = ({
  disableTables,
  bindUpcomingUnconfirmed,
  bindAllConfirmed,
  accept,
  bookingId,
}: IHandleBookingDecisionProps) => {
  const {
    userId,
    userType,
    authHeaderOptions,
    notifySuccess,
    notifyError,
    setShowAccept,
  } = useApp();

  //   const handleRejectClick = (bookingId: string) => {
  const handleRejectClick = () => {
    disableTables = true;

    if (userType === User.Skipper) {
      // this should NOT take the skipper id, and status is also unnecessary
      const skipperRejectBookingModel: WetwheelsApi.SkipperAuthBooking = {
        skipperId: userId,
        bookingId: bookingId,
        status: "reject",
      };

      new WetwheelsApi.BookingApi()
        .skipperRejectBooking(skipperRejectBookingModel, authHeaderOptions)
        .then(({ code, data, message, success }: ApiResponse) => {
          if (success) {
            notifySuccess(message);
            bindUpcomingUnconfirmed && bindUpcomingUnconfirmed();
            setShowAccept && setShowAccept(false);
          } else {
            notifyError(message);
          }
        });
    } else {
      // this should NOT take the skipper id, and status is also unnecessary
      const crewRejectBookingModel: WetwheelsApi.CrewAuthBooking = {
        crewId: userId,
        bookingId: bookingId,
        status: "reject",
      };

      new WetwheelsApi.BookingApi()
        .crewRejectBooking(crewRejectBookingModel, authHeaderOptions)
        .then(({ code, data, message, success }: ApiResponse) => {
          if (success) {
            notifySuccess(message);
            bindUpcomingUnconfirmed && bindUpcomingUnconfirmed();
            setShowAccept && setShowAccept(false);
          } else {
            notifyError(message);
          }
        });
    }
  };

  //   const handleAcceptClick = (bookingId: string) => {
  const handleAcceptClick = () => {
    disableTables = true;

    if (userType === User.Skipper) {
      // this should NOT take the skipper id, and status is also unnecessary
      const skipperAcceptBookingModel: WetwheelsApi.SkipperAuthBooking = {
        skipperId: userId,
        bookingId: bookingId,
        status: "accept",
      };

      new WetwheelsApi.BookingApi()
        .skipperAcceptBooking(skipperAcceptBookingModel, authHeaderOptions)
        .then(({ code, data, message, success }: ApiResponse) => {
          if (success) {
            notifySuccess(message);
            bindUpcomingUnconfirmed && bindUpcomingUnconfirmed();
            bindAllConfirmed && bindAllConfirmed();
            setShowAccept && setShowAccept(false);
          } else {
            notifyError(message);
          }
        });
    } else {
      // this should NOT take the skipper id, and status is also unnecessary
      const crewAcceptBookingModel: WetwheelsApi.CrewAuthBooking = {
        crewId: userId,
        bookingId: bookingId,
        status: "accept",
      };

      new WetwheelsApi.BookingApi()
        .crewAcceptBooking(crewAcceptBookingModel, authHeaderOptions)
        .then(({ code, data, message, success }: ApiResponse) => {
          if (success) {
            notifySuccess(message);
            bindUpcomingUnconfirmed && bindUpcomingUnconfirmed();
            bindAllConfirmed && bindAllConfirmed();
            setShowAccept && setShowAccept(false);
          } else {
            notifyError(message);
          }
        });
    }
  };

  return (
    <button
      className={`a-button v-button-${accept ? "success" : "warning"}`}
      onClick={accept ? () => handleAcceptClick() : () => handleRejectClick()}>
      {accept ? "Accept" : "Reject"}
    </button>
  );
};

export interface IHandleBookingDecisionProps {
  bindAllConfirmed?: () => void;
  bindUpcomingUnconfirmed?: () => void;
  disableTables: boolean;
  accept: boolean;
  bookingId: string;
}

export default HandleBookingDecision;
