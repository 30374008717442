import { Progress } from "antd";
import React from "react";

const BookingStatusIndicator = ({
  percentage,
}: IBookingStatusIndicatorProps) => {
  return (
    <Progress
      strokeColor={
        percentage < 25 ? "red" : percentage === 100 ? "green" : "blue"
      }
      percent={percentage}
      steps={4}
    />
  );
};

export default BookingStatusIndicator;

export interface IBookingStatusIndicatorProps {
  percentage: number;
}
