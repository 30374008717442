import React from "react";
import { Row, Col, Result } from "antd";

function RegisterEmail() {
  return (
    <>
      <Row className="mt-l mb-l">
        <Col md={{ span: 12, push: 6 }} xs={{ span: 22, push: 1 }}>
          <Result
            status="success"
            title="You have successfully registered"
            subTitle="We have sent you an email to confirm your account. Please follow the instructions to continue."
          />
        </Col>
      </Row>
    </>
  );
}

export default RegisterEmail;
