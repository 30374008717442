import React, { useState, useEffect } from "react";
import { Layout, Form, Input, notification, Collapse, Button } from "antd";
import IClient from "../../../interfaces/IClients";
import { useApp } from "../../../AppContext";

import * as WetwheelsApi from "../../../client-swagger/api";

const { Panel } = Collapse;

function DashboardAccount() {
  const [value, setValue] = useState<IClient>(null);
  const { userId, jwtToken, authHeaderOptions } = useApp();

  const [form] = Form.useForm();

  async function getAccount() {
    const { code, data } = await new WetwheelsApi.AccountApi().getAccount(
      userId,
      authHeaderOptions
    );

    if (code !== 200) return;

    // make shape with data
    var tempAccount: IClient = {
      firstName: data.firstName,
      lastName: data.lastName,
      contactNumber: data.contactNumber,
      email: data.email,
    };

    setValue(tempAccount);
  }

  async function handleSaveForm(values: any) {
    // values just returns changed fields.
    // make object for update

    var newTempData: WetwheelsApi.UpdateAccount = {
      userId: userId,
      firstName: values.firstName,
      lastName: values.lastName,
      contactNumber: values.contactNumber,
      email: values.email,
      oldPassword: values.oldPassword,
      newPassword: values.newPassword,
      confirmNewPassword: values.confirmNewPassword,
    };

    const {
      message,
      success,
    } = await new WetwheelsApi.AccountApi().updateAccount(
      newTempData,
      authHeaderOptions
    );

    //if (code !== 200) return;
    if (!success) {
      notification.success({
        message: "Something went wrong",
        description: message,
        duration: 20,
      });
      return;
    }

    notification.success({
      message: "Success",
      description: message,
      duration: 3,
    });
  }

  useEffect(() => {
    getAccount();
  }, []);

  return value ? (
    <>
      <Layout>
        <Layout.Header
          className="mb-m"
          style={{ padding: 0, background: "none", width: "100%" }}>
          <h2>Account</h2>
        </Layout.Header>
        <Layout.Content>
          <Form
            labelCol={{ sm: { span: 12 }, lg: { span: 10 } }}
            wrapperCol={{ sm: { span: 12 }, lg: { span: 14 } }}
            form={form}
            onFinish={handleSaveForm}
            layout="horizontal"
            size="large"
            style={{ wordBreak: "break-word" }}>
            <Form.Item name="firstName" label="First name">
              <Input defaultValue={value.firstName} />
            </Form.Item>

            <Form.Item name="lastName" label="Last name">
              <Input defaultValue={value.lastName} />
            </Form.Item>

            <Form.Item name="contactNumber" label="Contact number">
              <Input defaultValue={value.contactNumber} />
            </Form.Item>

            <Form.Item name="email" label="Email">
              <Input defaultValue={value.email} />
            </Form.Item>

            <Collapse className="mb-l">
              <Panel header="Change password" key="1">
                <Form.Item name="oldPassword" label="Old password">
                  <Input type="password" />
                </Form.Item>

                <Form.Item name="newPassword" label="New password">
                  <Input type="password" />
                </Form.Item>

                <Form.Item
                  name="confirmNewPassword"
                  label="Confirm new password">
                  <Input type="password" />
                </Form.Item>
              </Panel>
            </Collapse>

            <div className="mb-l">
              <Button type="primary" htmlType="submit">
                Save
              </Button>
            </div>
          </Form>
        </Layout.Content>
      </Layout>
    </>
  ) : null;
}

export default DashboardAccount;
