import React, { useEffect } from "react";
import { useBooking } from "../../components/Booking/bookingContext";
import { Row, Col, Result } from "antd";
import BookingProgress from "../../components/Booking/bookingProgress";
import Banner from "../../components/Banner";
import { Link } from "react-router-dom";

function ConfirmBooking() {
  const { setStep, bookingId, uniqueRef } = useBooking();
  setStep(3);
  return (
    <>
      <Banner />
      <Row className="mb-l text-align-center">
        <Col md={{ span: 16, push: 4 }} xs={{ span: 20, push: 2 }}>
          <BookingProgress />
          <Result
            status="success"
            title="Provisional booking confirmed!"
            subTitle={`Booking reference: ${uniqueRef}.`}
          />

          <h4>We have received your booking, thank you.</h4>

          <p>
            We will be assigning a Skipper to your trip, Once the Skipper has
            been assigned, you will receive an email, asking you to complete the
            passenger manifest and sign the Risk Acknowledgement and Disclaimer.
            Please complete this in good time, before your trip, to reduce
            administration on the day.
          </p>
        </Col>
      </Row>
    </>
  );
}

export default ConfirmBooking;
