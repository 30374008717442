import React from "react";
import { Layout, Row, Col } from "antd";
import { NavLink } from "react-router-dom";
import { useApp } from "../AppContext";
import User from "../enums/User";
const { Header } = Layout;

const NavMenu = () => {
  const { jwtToken, logOut, userType } = useApp();
  const isAuthenticated = !!jwtToken;

  return (
    <Header className="o-header">
      <Row justify="space-around" align="middle">
        <Col lg={{ span: 11, offset: 1 }} md={24}>
          <NavLink to="/">
            <img
              src="images/logo-jersey.png"
              alt="Wetwheels"
              className="logo m-xs"
            />
          </NavLink>
        </Col>
        <Col lg={10} md={24}>
          <Row justify="end">
            <Col>
              {" "}
              <NavLink className="nav-link" to="/dashboard/bookings">
                {isAuthenticated
                  ? userType === User.Admin
                    ? "Admin Dashboard"
                    : userType === User.Skipper
                    ? "Skipper Dashboard"
                    : "Your bookings"
                  : "Log in"}
              </NavLink>
            </Col>{" "}
            {isAuthenticated && (
              <Col>
                {" "}
                <NavLink
                  className="nav-link"
                  onClick={() => {
                    logOut();
                  }}
                  to="/">
                  Log out
                </NavLink>
              </Col>
            )}
            {userType !== User.Admin && (
              <Col>
                {" "}
                <NavLink className="nav-link" to="/">
                  Book now
                </NavLink>
              </Col>
            )}
          </Row>
        </Col>
      </Row>
    </Header>
  );
};

export default NavMenu;

interface INavMenu {
  children?: React.ReactNode;
}
