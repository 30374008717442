import React from "react";
import { Link } from "react-router-dom";
import { Alert, Row } from "antd";

const ManifestAlert = ({ message, button, id, type, page }: IManifestAlertProps) => {
  return (
    <Alert
      style={{ width: "100%", marginTop: "3rem" }}
      className="mb-l"
      message={
        <Row justify="space-between" align="middle">
          <p style={{ transform: "translate(0, 50%)" }}>{message}</p>
          <Link to={`/dashboard/bookings/${id}/${page}`}>
            <button className="a-button v-button-secondary my-xs">
              {button}
            </button>
          </Link>
        </Row>
      }
      type={type}
    />
  );
};

export default ManifestAlert;

export interface IManifestAlertProps {
  message: any;
  button: string;
  id: string;
  type: "success" | "info" | "warning" | "error";
  page: "manifest" | "disclaimer"
}
