import React from "react";

import UserAdd from "../../../components/User/Add/userAdd";

const DashboardCrewAdd = ({ closeWindow }: IDashboardClientAddProps) => {
  return <UserAdd closeWindow={closeWindow} role="crew" />;
};

export default DashboardCrewAdd;

interface IDashboardClientAddProps {
  closeWindow: () => void;
}
