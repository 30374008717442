import React from "react";
import { Row, Col, Card } from "antd";
import moment from "moment";
import CardRow from "../../../../components/Card/cardRow";

export interface IManifestPassengerListProps {
  bookingData: any;
}

const ManifestPassengerCard = ({
  bookingData,
}: IManifestPassengerListProps) => {
  return (
    <Row className="mb-s" gutter={16}>
      {bookingData.manifest.passengers.map((element: Passenger, index: any) => (
        <Col lg={8} className="mb-s" key={`passenger-${index}`}>
          <Card title={`${element.firstName} ${element.lastName}`}>
            <CardRow
              label="Wheelchair user?"
              value={element.isWheelchairUser ? "Yes" : "No"}
            />
            <CardRow
              label="Disabled, non wheelchair user?"
              value={element.isDisabledNonWheelchair ? "Yes" : "No"}
            />
            <CardRow label="Age" value={element.age} />
            <CardRow label="Gender" value={element.gender} />
            <CardRow
              label="Repeat user?"
              value={element.isRepeatUser ? "Yes" : "No"}
            />
            <CardRow
              label="Has provided photo consent?"
              value={element.hasGivenPhotoConsent ? "Yes" : "No"}
            />
          </Card>
        </Col>
      ))}
    </Row>
  );
};

export default ManifestPassengerCard;

export interface Passenger {
  firstName?: string;
  lastName?: string;
  isWheelchairUser?: boolean;
  hasGivenPhotoConsent?: boolean;
  isDisabledNonWheelchair?: boolean;
  dateOfBirth?: string;
  age?: string;
  gender?: string;
  isRepeatUser?: string;
}
