import React, { useState, useEffect } from "react";
import UserDetail from "../../../components/User/Detail/userDetail";
import BookingList from "../../../components/Booking/bookingList";
import { useApp } from "../../../AppContext";
import * as WetwheelsApi from "../../../client-swagger/api";

const DashboardClientDetail = ({
  user,
  closeWindow,
}: IDashboardClientDetailProps) => {
  const { authHeaderOptions, notifyError } = useApp();

  const [bookings, setBookings] = useState<any[]>(null);

  const fetchBookings = async () => {
    if (!user || !user.id) {
      return;
    }
    const {
      success,
      data,
      message,
    } = await new WetwheelsApi.BookingApi().bookingsByUser(
      user.id,
      authHeaderOptions
    );

    if (!success) {
      notifyError(message);
    }

    setBookings(data);
  };

  useEffect(() => {
    const getData = async () => {
      await fetchBookings();
    };

    getData();
  }, [user]);

  return (
    <>
      <UserDetail
        message="Scroll down to see the user's bookings"
        closeWindow={closeWindow}
        user={user}
      />
      <h3>Bookings</h3>
      <BookingList bookings={bookings} />
    </>
  );
};

export default DashboardClientDetail;

interface IDashboardClientDetailProps {
  user: any;
  closeWindow: () => void;
}
