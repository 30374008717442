import React, { useState } from "react";
import { Form, Input, Button, Col, Row } from "antd";
import { Redirect } from "react-router";
import { AccountApi, CreateUser } from "../../../client-swagger/api";

function Temp() {
  return (
    <>
      <h1>Sorry, registration is currently disabled.</h1>
      <p>
        Please call +447797919006 or email{" "}
        <a href="mailto:operations@wetwheels.je">operations@wetwheels.je</a>
      </p>
    </>
  );
}

export default Temp;
