import React from "react";
import UserDetail from "../../../components/User/Detail/userDetail";

const DashboardAdminDetail = ({
  user,
  closeWindow,
}: IDashboardAdminDetailProps) => {
  return <UserDetail closeWindow={closeWindow} user={user} />;
};

export default DashboardAdminDetail;

interface IDashboardAdminDetailProps {
  user: any;
  closeWindow: () => void;
}
