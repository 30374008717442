import React, { useState } from "react";
import { useBooking } from "../../components/Booking/bookingContext";
import { Row, Col, Button } from "antd";
import BookingProgress from "../../components/Booking/bookingProgress";
import BookingSummary from "../../components/Booking/bookingSummary";
import Banner from "../../components/Banner";
import { useApp } from "../../AppContext";

import * as WetwheelsApi from "../../client-swagger/api";
import { Redirect } from "react-router-dom";

function ReviewBooking() {
  const [error, setError] = useState<string>("");
  const [confirm, setConfirm] = useState<boolean>(false);
  const [, setCancel] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(false);
  const { bookingModel, setBookingModel, userId, authHeaderOptions } = useApp();

  const { setStep, setBookingId, setUniqueRef } = useBooking();
  setStep(2);

  if (!bookingModel) {
    return <Redirect to="/" />;
  }

  const handleConfirmClick = async () => {
    setDisabled(true);

    const request: WetwheelsApi.CreateBooking = {
      userId: userId,
      eventTypeId: bookingModel.activityId,
      startDate: bookingModel.timeStart,
      endDate: bookingModel.timeEnd,
      numberOfPassengers: bookingModel.numberOfPassengers,
      numberOfWheelchairs: bookingModel.numberOfWheelChairs,
      numberOfNonWheelchairDisabled: bookingModel.numberOfNonWheelchairDisabled,
      additionalInformation: bookingModel.additionalInformation,
    };

    const {
      data,
      success,
      message,
    } = await new WetwheelsApi.BookingApi().createBooking(
      request,
      authHeaderOptions
    );

    if (success) {
      setBookingId(data.id);
      setUniqueRef(data.uniqueKey);
      setConfirm(true);
      // used all we need, remove the booking model
      setBookingModel(null);
    } else {
      setError(message);
      setDisabled(false);
    }
  };

  const handleCancelClick = async () => {
    setBookingModel(null);
    setCancel(true);
  };

  //  cancel ? (
  //   <Redirect to="/" />
  // ) : confirm ? (
  //   <Redirect to="/booking/confirm" />
  // ) :

  return confirm ? (
    <>
      <Redirect to={`/booking/confirm`} />
    </>
  ) : (
    <>
      <Banner />

      {error && (
        <>
          <p className="error">{error}</p>
        </>
      )}
      <Row>
        <Col
          md={{ span: 16, offset: 4 }}
          sm={{ span: 22, offset: 1 }}
          xs={{ span: 20, offset: 2 }}>
          <BookingProgress />

          <BookingSummary />
        </Col>
      </Row>

      <Row>
        <Col span={4} offset={10} className="mt-m center-xs">
          <Button
            onClick={handleConfirmClick}
            htmlType="button"
            disabled={disabled}
            type="primary"
            size="large">
            Confirm booking {">"}
          </Button>
        </Col>
      </Row>
      <Row>
        <Col span={4} offset={10} className="mt-m center-xs">
          <Button onClick={handleCancelClick} size="middle" type="default">
            Cancel and start again
          </Button>
        </Col>
      </Row>
    </>
  );
}

export default ReviewBooking;
