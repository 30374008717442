import React, { useEffect, useState } from "react";
import { Layout, Row, Col, Table } from "antd";
import moment from "moment";
import { useHistory } from "react-router-dom";

import * as WetwheelsApi from "../../../../client-swagger/api";
import { useApp } from "../../../../AppContext";
import { ApiResponse } from "../../../../client-swagger/api";
import AuthHeaderOptions from "../../../../AuthHeaderOptions";
import BookingStatusIndicator from "../../../../components/Booking/bookingStatusIndicator";
import { Breakpoint } from "antd/lib/_util/responsiveObserve";

const DashboardBookingsClient = () => {
  const history = useHistory();
  const { userId, jwtToken } = useApp();
  const [list, setList] = useState();
  const [unconfirmedList, setUnconfirmedList] = useState();
  const [isListLoading, setIsListLoading] = useState<boolean>(true);
  const [previousList, setPreviousList] = useState();
  const [isPreviousListLoading, setIsPreviousListLoading] = useState<boolean>(
    true
  );
  const authHeaderOptions = AuthHeaderOptions(jwtToken);

  const columns = [
    {
      title: "Reference",
      dataIndex: "uniqueKey",
      key: "uniqueKey",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      responsive: ["lg"] as Breakpoint[],
      render: (text: string, record: any) => {
        return record.firstName + " " + record.lastName;
      },
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (text: string, record: any) => {
        return `${moment(text).format("dddd")}, ${moment(text).format(
          "LL"
        )}: ${moment(record.startDateTime).format("LT")} to ${moment(
          record.endDateTime
        ).format("LT")}`;
      },
    },
    {
      title: "Activity",
      dataIndex: "activityName",
      key: "activityName",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      responsive: ["sm"] as Breakpoint[],
      render: (text: string, record: any) => (
        <BookingStatusIndicator
          percentage={record.statusPercentage}></BookingStatusIndicator>
      ),
    },
  ];

  useEffect(() => {
    new WetwheelsApi.BookingApi()
      .myBookings("upcoming", "confirmed", authHeaderOptions)
      .then(({ success, data }: ApiResponse) => {
        if (success) {
          setList(data);
        }
        setIsListLoading(false);
      });

    new WetwheelsApi.BookingApi()
      .myBookings("upcoming", "unconfirmed", authHeaderOptions)
      .then(({ success, data }: ApiResponse) => {
        if (success) {
          setUnconfirmedList(data);
        }
        setIsListLoading(false);
      });

    new WetwheelsApi.BookingApi()
      .myBookings("past", "", authHeaderOptions)
      .then(({ success, data }: ApiResponse) => {
        if (success) {
          setPreviousList(data);
        }
        setIsPreviousListLoading(false);
      });
  }, [userId, jwtToken]);

  return (
    <>
      <Layout>
        <Layout.Header
          className="mb-m"
          style={{ padding: 0, background: "none", width: "100%" }}>
          <h2>My bookings</h2>
        </Layout.Header>
        <Layout.Content>
          <Row gutter={[16, 16]}>
            <Col md={{ span: 24 }}>
              <h4>Upcoming unconfirmed bookings</h4>
              <Table
                onRow={(record) => {
                  return {
                    onClick: () => {
                      const ref = `/dashboard/bookings/${record.bookingId}`;
                      history.push(ref);
                    },
                  };
                }}
                loading={isListLoading}
                rowKey={(record) => record.bookingId}
                columns={columns}
                dataSource={unconfirmedList}
                pagination={false}
              />
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col md={{ span: 24 }}>
              <h4>Upcoming confirmed bookings</h4>
              <Table
                onRow={(record) => {
                  return {
                    onClick: () => {
                      const ref = `/dashboard/bookings/${record.bookingId}`;
                      history.push(ref);
                    },
                  };
                }}
                loading={isListLoading}
                rowKey={(record) => record.bookingId}
                columns={columns}
                dataSource={list}
                pagination={false}
              />
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col md={{ span: 24 }}>
              <h4>Previous bookings</h4>
              <Table
                onRow={(record) => {
                  return {
                    onClick: () => {
                      const ref = `/dashboard/bookings/${record.bookingId}`;
                      history.push(ref);
                    },
                  };
                }}
                rowKey={"key"}
                columns={columns}
                dataSource={previousList}
                loading={isPreviousListLoading}
              />
            </Col>
          </Row>
        </Layout.Content>
      </Layout>
    </>
  );
};

export default DashboardBookingsClient;
