import React from "react";
import { Row, Col, Button } from "antd";
import { Link } from "react-router-dom";
import moment from "moment";
import UserDetail from "../../../components/User/Detail/userDetail";

function DashboardSkipperDetail({
  skipperData,
  dayAvailability,
  closeWindow,
}: IDashboardSkipperProps) {
  return (
    skipperData &&
    skipperData.user && (
      <>
        <UserDetail closeWindow={closeWindow} user={skipperData.user} />

        <h3 className="mt-l">
          Availability{" "}
          <Link to={`/dashboard/availability/${skipperData.id}`}>
            <Button size="middle" type="primary">
              Edit
            </Button>
          </Link>
        </h3>
        <h4>General availability</h4>
        <div className="mb-l">
          {dayAvailability
            ? dayAvailability.map((day: any) => (
                <Row key={day.dayName}>
                  <Col span={6} className="">
                    {day.dayName}
                  </Col>
                  <Col span={6} className="">
                    {day.availability}
                  </Col>
                </Row>
              ))
            : null}
        </div>
        <h4>Unavailable on specific days</h4>
        <div className="mb-l">
          {skipperData && skipperData.skipperUnavailables ? (
            skipperData.skipperUnavailables.map((unavail: any) => (
              <Row key={unavail.id}>
                <Col span={8} className="">
                  {moment(unavail.date).format("LL")}
                </Col>
              </Row>
            ))
          ) : (
            <p>No specific days set</p>
          )}
        </div>
      </>
    )
  );
}

export default DashboardSkipperDetail;

interface IDashboardSkipperProps {
  skipperData: any;
  dayAvailability: any;
  closeWindow: () => void;
}
