import React, { useState } from "react";
import { Row, Col, Form, Input, Button, Result } from "antd";
import { AccountApi, ResetPasswordRequest } from "../../../client-swagger/api";

function ForgotPassword() {
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");

  const onFinish = async (value: ResetPasswordRequest) => {
    const register = await new AccountApi().requestResetPassword(value);
    if (!register.success) {
      setErrorMessage(register.message);
      return;
    }
    setSubmitted(true);
  };

  const onFinishFailed = () => {};

  return submitted ? (
    <Row className="mt-l mb-l">
      <Col md={{ span: 12, push: 6 }} xs={{ span: 22, push: 1 }}>
        <Result
          status="success"
          title="We have sent you an email"
          subTitle="We have sent you a link to reset your password if you have an account with that email."
        />
      </Col>
    </Row>
  ) : (
    <>
      <Row className="mt-l mb-l">
        <Col md={{ span: 12, push: 6 }} xs={{ span: 20, push: 2 }}>
          <h3>Forgotten your password?</h3>
          <h4>Let us help you out.</h4>
          {errorMessage && <p className="error">{errorMessage}</p>}

          <p>
            Please enter your email address and click Submit. We will send you
            an email with instructions for how to reset your password.
          </p>
          <Form
            name="basic"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            className="o-form">
            <Form.Item
              label="Email"
              name="email"
              rules={[{ required: true, message: "Please input your email!" }]}>
              <Input />
            </Form.Item>

            <Button
              className="a-button v-button-primary"
              type="primary"
              htmlType="submit">
              Submit
            </Button>
          </Form>
        </Col>
      </Row>
    </>
  );
}

export default ForgotPassword;
