import React, { useState, useRef, useEffect } from "react";
import { useCalendar } from "./context";
import { Row, Col, Select, Form, Spin, Button } from "antd";
import Day from "./day";
import TextArea from "antd/lib/input/TextArea";
import ActivitySelect from "./activitySelect";
import SlotSelect from "./slotSelect";
import IBookingModel from "../../interfaces/IBookingModel";
import { useApp } from "../../AppContext";
import moment from "moment";
import { Redirect } from "react-router-dom";

function Calendar() {
  const { bookingModel, setBookingModel, setRedirectTo } = useApp();
  const {
    handlePrevWeekClick,
    handleNextWeekClick,
    handleMonthSelect,
    daySelected,
    startTimeSelected,
    endTimeSelected,
    daysOfWeek,
    slotsSelected,
    slotSize,
    activitySelected,
    activityNameSelected,
  } = useCalendar();
  const [months, setMonths] = useState<Array<IMonthItem>>(null);

  const [numberOfPassengers, setNumberOfPassengers] = useState<number>(0);
  const [numberOfWheelChairPassengers, setNumberOfWheelChairPassengers] =
    useState<number>(0);
  const [
    numberOfNonWheelchairDisabledPassengers,
    setNumberOfNonWheelchairDisabledPassengers,
  ] = useState<number>(0);
  const [additionalInformation, setAdditionalInformation] =
    useState<string>("");
  const [continueToConfirm, setContinueToConfirm] = useState<boolean>(false);
  const [form] = Form.useForm();

  const handleBookingFormClick = async () => {
    try {
      const values = await form.validateFields();

      if (values) {
        const bm: IBookingModel = {
          date: daySelected,
          activityId: activitySelected,
          activityType: activityNameSelected,
          slotSize: slotSize,
          timeStart: startTimeSelected,
          timeEnd: endTimeSelected,
          numberOfPassengers: numberOfPassengers,
          numberOfWheelChairs: numberOfWheelChairPassengers,
          numberOfNonWheelchairDisabled:
            numberOfNonWheelchairDisabledPassengers,
          additionalInformation: additionalInformation,
        };

        setBookingModel(bm); // in mem
        setContinueToConfirm(true); // for rerender
        setRedirectTo(`/booking/review`); // for after login
      }
    } catch (err) {
      throw new Error(err);
    }
    // store info on local storage
  };

  useEffect(() => {
    const months = new Array<IMonthItem>();
    let i = 0;
    while (i < 12) {
      let date = moment().add(i, "month").startOf("month");
      months.push({
        dateString: date.format("YYYY-MM-DD"),
        name: date.format("MMMM YYYY"),
      });
      i++;
    }
    setMonths(months);
  }, []);

  //return bookingModel ? <Redirect to={`/booking/review`} /> : (
  return continueToConfirm ? (
    <Redirect to="booking/review" />
  ) : (
    <>
      <div className="o-calendar">
        <>
          {/* TODO Need to show a loading animation to show when dates are being gathered  */}
          <Row justify="space-between" className="mb-l">
            <h3>1 of 4 - Select date</h3>
          </Row>
          <Row justify="space-between" className="mb-l">
            <p>
              Please select the date that you wish to book. Dates are only
              clickable if there is availability. Once you've selected the date,
              you can then choose the trip type and start time.
            </p>
          </Row>

          <Row className="mb-m ml-l">
            <Col className="month-select" style={{ width: "30vh" }}>
              {months && (
                <Select
                  style={{ width: "100%" }}
                  onChange={(value: any, option: any) => {
                    if (value) {
                      handleMonthSelect(value);
                    }
                  }}
                  placeholder={`Select a month`}
                >
                  {/* <Select.Option value="">[Select a month]</Select.Option> */}
                  {months.map((month) => {
                    return (
                      <Select.Option value={month.dateString}>
                        {month.name}
                      </Select.Option>
                    );
                  })}
                </Select>
              )}
            </Col>
          </Row>
          <Spin
            spinning={!daysOfWeek}
            style={{
              width: "100%",
            }}
          >
            {daysOfWeek && (
              <Row justify="center" className="mb-l">
                <Col
                  span={24}
                  className="previousVertical"
                  onClick={handlePrevWeekClick}
                >
                  <span
                    style={{ display: "flex", alignItems: "flex-end" }}
                    className="a-interactive-link"
                  >
                    {"<"}
                  </span>
                </Col>

                <Col
                  span={1}
                  className="previous"
                  onClick={handlePrevWeekClick}
                >
                  <span className="a-interactive-link">{"<"}</span>
                </Col>

                <Col span={22}>
                  <Row justify="space-between" className="days">
                    <Day day={0} />
                    <Day day={1} />
                    <Day day={2} />
                    <Day day={3} />
                    <Day day={4} />
                    <Day day={5} />
                    <Day day={6} />
                  </Row>
                </Col>

                <Col span={1} className="next" onClick={handleNextWeekClick}>
                  <span className="a-interactive-link">{">"}</span>
                </Col>

                <Col
                  span={24}
                  className="nextVertical"
                  onClick={handleNextWeekClick}
                >
                  <span
                    style={{ display: "flex", alignItems: "flex-end" }}
                    className="a-interactive-link"
                  >
                    {">"}
                  </span>
                </Col>
              </Row>
            )}
          </Spin>
        </>

        {daySelected && (
          <>
            <ActivitySelect />
          </>
        )}

        {activitySelected && (
          <>
            <SlotSelect />
          </>
        )}

        {startTimeSelected && endTimeSelected && slotsSelected && (
          //  - Any additional notes
          <>
            <Row gutter={[16, 16]} justify="space-between" className="mb-l">
              <h3>4 of 4 - Party information</h3>
            </Row>

            <Form
              labelCol={{ span: 12 }}
              wrapperCol={{ span: 12 }}
              layout="horizontal"
              form={form}
              initialValues={{
                additionalInformation: "",
              }}
            >
              <Form.Item
                hasFeedback
                name="numberPassengers"
                label="Total no. of passengers"
                rules={[
                  {
                    required: true,
                    message: "Please select number of passengers",
                  },
                ]}
                // validateStatus="error"
                // help="Please select number of passengers"
              >
                <Select
                  onChange={(value: number) => setNumberOfPassengers(value)}
                  value={numberOfPassengers}
                  placeholder="Please select below"
                >
                  <Select.Option value="1">1</Select.Option>
                  <Select.Option value="2">2</Select.Option>
                  <Select.Option value="3">3</Select.Option>
                  <Select.Option value="4">4</Select.Option>
                  <Select.Option value="5">5</Select.Option>
                  <Select.Option value="6">6</Select.Option>
                  <Select.Option value="7">7</Select.Option>
                  <Select.Option value="8">8</Select.Option>
                  <Select.Option value="9">9</Select.Option>
                  <Select.Option value="10">10</Select.Option>
                </Select>
              </Form.Item>
              <Form.Item
                hasFeedback
                name="numberWheelchairs"
                label="No. of wheelchairs"
                rules={[
                  {
                    required: true,
                    message: "Please select number of wheelchairs",
                  },
                ]}
                // validateStatus="error"
                // help="Please select number of wheelchairs"
              >
                <Select
                  onChange={(value: number) =>
                    setNumberOfWheelChairPassengers(value)
                  }
                  value={numberOfWheelChairPassengers}
                  placeholder="Please select below"
                >
                  <Select.Option value="0">0</Select.Option>
                  <Select.Option value="1">1</Select.Option>
                  <Select.Option value="2">2</Select.Option>
                  <Select.Option value="3">3</Select.Option>
                </Select>
              </Form.Item>
              <Form.Item
                hasFeedback
                name="numberOfNonWheelchairDisabled"
                label="No. of special needs passengers"
                rules={[
                  {
                    required: true,
                    message: "Please select number of special needs passengers",
                  },
                ]}
              >
                <Select
                  onChange={(value: number) =>
                    setNumberOfNonWheelchairDisabledPassengers(value)
                  }
                  value={numberOfNonWheelchairDisabledPassengers}
                  placeholder="Please select below"
                >
                  <Select.Option value="0">0</Select.Option>
                  <Select.Option value="1">1</Select.Option>
                  <Select.Option value="2">2</Select.Option>
                  <Select.Option value="3">3</Select.Option>
                  <Select.Option value="4">4</Select.Option>
                  <Select.Option value="5">5</Select.Option>
                  <Select.Option value="6">6</Select.Option>
                  <Select.Option value="7">7</Select.Option>
                  <Select.Option value="8">8</Select.Option>
                  <Select.Option value="9">9</Select.Option>
                  <Select.Option value="10">10</Select.Option>
                </Select>
              </Form.Item>
              <Form.Item
                name="additionalInformation"
                label="Additional information"
                rules={[
                  {
                    required: false,
                  },
                ]}
              >
                <TextArea
                  rows={4}
                  value={additionalInformation}
                  onChange={(e) => setAdditionalInformation(e.target.value)}
                />
              </Form.Item>

              {daySelected && startTimeSelected && endTimeSelected && slotSize && (
                <Row justify="center" className="mt-m">
                  <Col>
                    <Button
                      onClick={handleBookingFormClick}
                      htmlType="button"
                      type="primary"
                      size="large"
                    >
                      Proceed with booking {">"}
                    </Button>
                  </Col>
                </Row>
              )}
            </Form>
          </>
        )}
      </div>
    </>
  );
}

export default Calendar;

export interface IMonthItem {
  name: string;
  dateString: string;
}
