import React, { useState, useEffect } from "react";
import {
  Layout,
  notification,
  Form,
  Input,
  DatePicker,
  Select,
  Spin,
  Modal,
  Switch,
  Alert,
} from "antd";
import { Link, Redirect, useParams } from "react-router-dom";
import moment from "moment";
import { useApp } from "../../../../AppContext";

import PassengerDetailCollapsePanel from "../Client/Passenger/detailsCollapsePanel";
import * as WetwheelsApi from "../../../../client-swagger/api";
import { ApiResponse, PassengerManifest } from "../../../../client-swagger/api";
import ManifestAddOrUpdate from "./manifestAddOrUpdate";
import { FormInstance } from "antd/lib/form";

const DashboardBookingClientManifest = () => {
  const [redirect, setRedirect] = useState<boolean>(false);
  const [showAddPassengerModal, setShowAddPassengerModal] =
    useState<boolean>(false);
  const [isPassengerUpdate, setIsPassengerUpdate] = useState<boolean>(false);
  const {
    userId,
    manifestData,
    setManifestData,
    selectedPassenger,
    setSelectedPassenger,
    authHeaderOptions,
  } = useApp();
  const [bookingData, setBookingData] = useState(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const { id }: any = useParams();

  const reloadManifest = () => {
    new WetwheelsApi.ManifestApi()
      .manifestBookingIdGet(id, authHeaderOptions)
      .then(({ code, data, success, message }: ApiResponse) => {
        if (success) {
          setManifestData(data);
        } else {
          // CREATE?
          new WetwheelsApi.ManifestApi()
            .manifestCreatePost(
              {
                bookingId: id,
                userId: userId,
                passengers: [],
              },
              authHeaderOptions
            )
            .then(({ code, data, success, message }: ApiResponse) => {
              if (success) {
                setManifestData(data);
              } else {
                showError(message);
              }
            });
        }
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (!id) {
      return;
    }

    // get data and store in a useState
    new WetwheelsApi.BookingApi()
      .booking(id, authHeaderOptions)
      .then(({ code, data, success, message }: ApiResponse) => {
        if (success) {
          setBookingData(data);
        } else {
          showError(message);
        }
      });
  }, [id]);

  useEffect(() => {
    if (!bookingData) {
      return;
    }

    if (bookingData.manifest) {
      // we already have it
      setManifestData(bookingData.manifest);
      setIsLoading(false);
      return;
    }

    // get data and store in a useState
    reloadManifest();
  }, [bookingData]);

  const showSuccess = (message?: string) => {
    notification.success({
      message: "Success",
      description: message,
    });
  };

  const showError = (message?: string) => {
    notification.error({
      message: "Error",
      description: message,
    });
  };

  const handleSaveForm = () => {
    notification.success({
      message: "Success",
      description: "Manifest has been saved",
      duration: 1.5,
      onClose: () => {
        setManifestData(true);
        setRedirect(true);
      },
    });
  };

  const handleAddPassenger = () => {
    setSelectedPassenger(null);
    setShowAddPassengerModal((showAddPassengerModal) => !showAddPassengerModal);
  };

  const handleCancelSavePassenger = () => {
    setShowAddPassengerModal(false);
    setIsPassengerUpdate(false);
  };

  const handleSavePassenger = (values: any) => {
    const passenger: PassengerManifest = values;

    if (isPassengerUpdate) {
      new WetwheelsApi.ManifestApi()
        .manifestManifestIdPassengerUpdatePassengerIdPatch(
          selectedPassenger.id,
          selectedPassenger.manifestId,
          passenger,
          authHeaderOptions
        )
        .then(({ data, success, message }: ApiResponse) => {
          if (success) {
            showSuccess(message);
            // addPassengerForm.resetFields();
            setShowAddPassengerModal(false);
            setIsLoading(true);
            setIsPassengerUpdate(false);
            reloadManifest();
          } else {
            showError("Please complete manifest before submitting");
          }
        });
    } else {
      passenger.manifestId = manifestData.id;
      // get data and store in a useState
      new WetwheelsApi.ManifestApi()
        .manifestAddPassengerPost(passenger, authHeaderOptions)
        .then(({ code, data, success, message }: ApiResponse) => {
          if (success) {
            showSuccess(message);
            //addPassengerForm.resetFields();
            setShowAddPassengerModal(false);
            setIsLoading(true);
            reloadManifest();
            // reload the manifest!
          } else {
            showError("Please complete manifest before submitting");
          }
        });
    }
  };

  const handleDeletePassenger = (passengerId: string) => {
    const manifestId = manifestData.id;

    new WetwheelsApi.ManifestApi()
      .manifestManifestIdPassengerPassengerIdDelete(
        passengerId,
        manifestId,
        authHeaderOptions
      )
      .then(({ code, data, success, message }: ApiResponse) => {
        if (success) {
          showSuccess(message);
          setIsLoading(true);
          reloadManifest();
        } else {
          showError(message);
        }
      });
  };

  const handleUpdatePassenger = (passenger: any) => {
    setSelectedPassenger(passenger);
    setShowAddPassengerModal(true);
    setIsPassengerUpdate(true);
  };

  return redirect ? (
    <Redirect to={`/dashboard/bookings/${id}`} />
  ) : (
    <>
      <Spin spinning={isLoading}>
        {bookingData && manifestData ? (
          <Layout>
            <Layout.Header
              className="mb-m"
              style={{ padding: 0, background: "none", width: "100%" }}
            >
              <h2>Manifest for booking {bookingData.uniqueKey}</h2>
            </Layout.Header>
            <Layout.Content>
              {manifestData.passengers && manifestData.passengers.length ? (
                <PassengerDetailCollapsePanel
                  passengers={manifestData.passengers}
                  isDisabled={true}
                  deleteHander={handleDeletePassenger}
                  updateHandler={handleUpdatePassenger}
                ></PassengerDetailCollapsePanel>
              ) : (
                <Alert
                  type="warning"
                  message="You have not yet added any passengers"
                  description="Please use the 'Add Passenger' button below to add passengers to your manifest"
                />
              )}

              <button
                className="a-button v-button-primary mt-m"
                onClick={handleAddPassenger}
              >
                Add passenger
              </button>
            </Layout.Content>
            <Layout.Footer
              style={{
                padding: "30px 0 0",
                background: "none",
                width: "100%",
              }}
            >
              <Link to={`/dashboard/bookings/${bookingData.id}`}>
                <button className="a-button v-button-primary mb-m">Back</button>
              </Link>
              <button
                className="a-button v-button-primary mb-m float-right"
                onClick={handleSaveForm}
              >
                Save
              </button>
            </Layout.Footer>
          </Layout>
        ) : null}

        <ManifestAddOrUpdate
          showAddPassengerModal={showAddPassengerModal}
          handleCancelSavePassenger={handleCancelSavePassenger}
          handleSavePassenger={handleSavePassenger}
        ></ManifestAddOrUpdate>
        {/*         
        <Modal
          title={`${selectedPassenger ? "Update" : "Add"} Passenger`}
          visible={showAddPassengerModal}
          okText="Save passenger"
          cancelText="Cancel"
          onOk={handleSavePassenger}
          onCancel={handleCancelSavePassenger}
          width="85vw">
          <Form
            labelCol={{ sm: { span: 24 }, lg: { span: 10 } }}
            wrapperCol={{ sm: { span: 24 }, lg: { span: 14 } }}
            layout="horizontal"
            form={addPassengerForm}
            initialValues={{
              firstName: selectedPassenger ? selectedPassenger.firstName : "",
              lastName: selectedPassenger ? selectedPassenger.lastName : "",
              isWheelchairUser: selectedPassenger ? selectedPassenger.isWheelchairUser : false,
              isDisabledNonWheelchair: selectedPassenger
                ? selectedPassenger.isDisabledNonWheelchair
                : false,
              hasGivenPhotoConsent: selectedPassenger
                ? selectedPassenger.hasGivenPhotoConsent
                : false,
              dateOfBirth: selectedPassenger ? moment(selectedPassenger.dateOfBirth) : undefined,
              isRepeatUser: selectedPassenger ? selectedPassenger.isRepeatUser : false,
              gender: selectedPassenger ? selectedPassenger.gender : "",
            }}>
            <Form.Item
              label="First name"
              name="firstName"
              rules={[
                {
                  required: true,
                  message: "Please add your first name",
                },
              ]}>
              <Input
                size="large"
              />
            </Form.Item>
            <Form.Item
              label="Last name"
              name="lastName"
              rules={[
                {
                  required: true,
                  message: "Please add your last name",
                },
              ]}>
              <Input
                size="large"
              />
            </Form.Item>
            <Form.Item
              label="Is wheelchair user?"
              name="isWheelchairUser"
              valuePropName="checked">
              <Switch />
            </Form.Item>
            <Form.Item
              label="Is special needs user?"
              name="isDisabledNonWheelchair"
              valuePropName="checked">
              <Switch />
            </Form.Item>
            <p>
              Because of the changes in data protection laws, we treat
              photographs very carefully. As a result, we have to ask your
              permission to use the photographs and want you to be clear on
              where and how we use them.
            </p>
            <p>
              By selecting Yes below, you grant Wetwheels Jersey, Wetwheels
              Operators and the Wetwheels Foundation, permission to take
              photographs and/or video of your group/family and full rights to
              use the images resulting from the photography/video filming, and
              any reproductions or adaptations of the images on our social media
              pages, including for fundraising, publicity or other purposes to
              help achieve the group’s aims. This might include (but is not
              limited to), the right to use them in our printed and online
              publicity, social media, press releases and funding applications.
            </p>
            <Form.Item
              label="Do you authorise us to use photos?"
              name="hasGivenPhotoConsent"
              valuePropName="checked">
              <Switch />
            </Form.Item>
            <Form.Item
              label="Date of birth"
              name="dateOfBirth"
              rules={[
                {
                  required: true,
                  message: "Please select date of birth",
                },
              ]}>
              <DatePicker size="large" placeholder="YYYY-MM-DD" />
            </Form.Item>
            <Form.Item
              label="Gender"
              name="gender"
              rules={[
                {
                  required: true,
                  message: "Please select gender",
                },
              ]}>
              <Select size="large" value={selectedPassenger ? selectedPassenger.gender : ""}>
                <Select.Option value="Male">Male</Select.Option>
                <Select.Option value="Female">Female</Select.Option>
                <Select.Option value="Unspecified">Not specified</Select.Option>
              </Select>
            </Form.Item>

            <Form.Item
              label="Has this person used Wetwheels before?"
              name="isRepeatUser"
              valuePropName="checked">
              <Switch />
            </Form.Item>
          </Form>
        </Modal> */}
      </Spin>
    </>
  );
};

export default DashboardBookingClientManifest;
