import React, { useState, useEffect } from "react";
import { Layout, Drawer, Row, Col } from "antd";
import Detail from "./dashboardAdminDetail";
import Add from "./dashboardAdminAdd";

import * as WetwheelsApi from "../../../client-swagger/api";
import { useApp } from "../../../AppContext";
import UserList from "../../../components/User/List/userList";

function DashboardAdmins() {
  const { authHeaderOptions, notifySuccess, notifyError } = useApp();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [list, setList] = useState();

  const [data, setData] = useState(null);
  const [drawerVisible, setDrawerVisible] = useState<boolean>(false);
  const [drawerAddVisible, setDrawerAddVisible] = useState<boolean>(false);

  let disable = false;

  const onClose = () => {
    setData(null);
    setDrawerVisible(false);
    setDrawerAddVisible(false);
    fetchList();
  };

  const handleNewClick = () => {
    setDrawerVisible(false);
    setDrawerAddVisible(true);
  };

  const handleRow = (record: any) => {
    return {
      onClick: () => {
        if (disable) return;
        if (record) {
          setData(record);
        }
        setDrawerAddVisible(false);
        setDrawerVisible(true);
      },
    };
  };

  const fetchList = async () => {
    setIsLoading(true);
    const { data, success, message } = await new WetwheelsApi.UserApi().admins(
      authHeaderOptions
    );

    if (!success) {
      notifyError(message);
      return;
    }

    setList(data);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchList();
  }, []);

  return (
    <>
      <Layout>
        <Layout.Header
          className="mb-m v-ant-layout-header"
          style={{ padding: 0, background: "none", width: "100%" }}>
          <Row justify="space-between" align="middle">
            <Col className="mr-m">
              <h2>Admins</h2>
            </Col>
            <Col>
              <button
                className="a-button v-button-primary"
                onClick={handleNewClick}>
                Add new admin
              </button>
            </Col>
          </Row>
        </Layout.Header>
        <Layout.Content>
          <UserList
            bindData={fetchList}
            handleRowClick={handleRow}
            isLoading={isLoading}
            list={list}></UserList>
        </Layout.Content>
      </Layout>
      <Drawer
        width={"75vw"}
        onClose={onClose}
        visible={drawerVisible}
        bodyStyle={{ paddingBottom: 80 }}>
        <Detail user={data} closeWindow={onClose} />
      </Drawer>

      <Drawer
        width={"75vw"}
        onClose={onClose}
        visible={drawerAddVisible}
        bodyStyle={{ paddingBottom: 80 }}>
        <Add closeWindow={onClose} />
      </Drawer>
    </>
  );
}

export default DashboardAdmins;
