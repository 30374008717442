import React from "react";
import Banner from "../../components/Banner";
import { Row, Col } from "antd";
import { Route } from "react-router-dom";
import Home from ".";
import BookingProgress from "../../components/Booking/bookingProgress";

function HomeRouter() {
  return (
    <>
      <Banner />
      <Row>
        <Col md={{span: 20, offset: 2}} sm={{span: 22, offset: 1}} xs={{span: 20, offset: 2}}>
            <BookingProgress />
            <Route exact path="/" component={Home} />
        </Col>
      </Row>
    </>
  );
}

export default HomeRouter;
