import React, { useState } from "react";
import { Form, Input, Button, Checkbox, Col, Row } from "antd";
import { Link, Redirect } from "react-router-dom";
import { useApp } from "../../AppContext";

import * as WetWheelsAPI from "../../client-swagger/api";

const Login = () => {
  const { setJwtToken, redirectTo, setRedirectTo } = useApp();
  const [errorMessage, setErrorMessage] = useState("");
  const [jwtUpdated, setJwtUpdated] = useState<boolean>(false);
  const { bookingModel, setIsNotAuthenticated } = useApp();

  const onFinish = async (values: WetWheelsAPI.Login) => {
    if (!values.email) return;

    setErrorMessage("Logging you in...");

    const {
      success,
      message,
      data,
    } = await new WetWheelsAPI.AccountApi().login(values);

    if (!success) {
      setErrorMessage(message);
      setTimeout(() => {
        setErrorMessage("");
      }, 5000);
      return;
    }

    if (bookingModel) {
      setRedirectTo("/booking/review");
    } else {
      setRedirectTo("/dashboard/bookings");
    }
    // we now should have authenticated so we should store the JWT that is returned
    setJwtToken(data);

    setIsNotAuthenticated(false);
    setJwtUpdated(true);
  };
  // TODO Look into why this isn't redirecting to the dashboard when you click login
  return jwtUpdated ? (
    <Redirect to={redirectTo} />
  ) : (
    <>
      <Row className="mt-l mb-l">
        <Col md={{ span: 12, offset: 6 }} xs={{ span: 20, offset: 2 }}>
          <p>
            Not a member, please{" "}
            <Link to="/login/register" className="a-interactive-link">
              register
            </Link>{" "}
            for an account
          </p>
          <div style={{ height: "50px" }}>
            {errorMessage && <p className="a-error">{errorMessage}</p>}
          </div>
          <Form
            name="basic"
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 10 }}
            initialValues={{ remember: true }}
            onFinish={onFinish}
            className="o-form"
          >
            <Form.Item
              label="Email"
              name="email"
              rules={[{ required: true, message: "Please input your email!" }]}
            >
              <Input className="col-16" size="large" />
            </Form.Item>

            <Form.Item
              label="Password"
              name="password"
              rules={[
                { required: true, message: "Please input your password!" },
              ]}
            >
              <Input.Password size="large" />
            </Form.Item>

            <Row>
              <Col span={10} offset={6}>
                <Form.Item name="remember" valuePropName="">
                  <Checkbox>Remember me</Checkbox>
                </Form.Item>

                <Form.Item>
                  <Button size="large" type="primary" htmlType="submit">
                    Log in
                  </Button>
                </Form.Item>

                <p>
                  <Link
                    to="/login/forgotPassword"
                    className="a-interactive-link"
                  >
                    Forgotten your password?
                  </Link>
                </p>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </>
  );
};

export default Login;
