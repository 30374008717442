import React, { useState, useEffect } from "react";
import { useCalendar } from "./context";
import { Row, Col, Select } from "antd";

import * as WetwheelsApi from "../../client-swagger/api";

function ActivitySelect() {
  const [selectValue, setSelectValue] = useState<string>("");

  const {
    activities,
    setActivities,
    setActivitySelected,
    setActivityNameSelected,
    setSlotSize,
    setSlotsSelected,
    setStartTimeSelected,
    setEndTimeSelected,
    daySelected,
    resetTime,
  } = useCalendar();

  useEffect(() => {
    setSelectValue("");
    new WetwheelsApi.BookingApi()
      .bookingListAvailableActivitiesByDayGet(daySelected.toJSON())
      .then(({ value }: any) => {
        setActivities(value.data);
      });
  }, [daySelected]);

  function handleTypeClick(value: any) {
    var activity = document.querySelector(
      `.${value.replace(/ /g, "").toLowerCase()}`
    ) as HTMLSelectElement;

    setSelectValue(value);

    setActivitySelected(activity.dataset?.id);
    // get slots
    setSlotsSelected(false);
    setStartTimeSelected(null);
    setEndTimeSelected(null);
    setActivityNameSelected(value);
    setSlotSize(Number(activity.dataset?.slots));

    resetTime();
  }

  return activities ? (
    <>
      <Row gutter={[16, 16]} justify="space-between" className="mb-l">
        <h3>2 of 4 - Select trip type</h3>
      </Row>
      <Row gutter={[16, 16]} justify="space-between" className="mb-l">
        <p>
          Please select the type of trip you'd like to take. Some trips require
          more time than others. A "slot" is about 2 hours.
        </p>
      </Row>
      <Row gutter={[16, 16]} justify="center" className="times mb-l">
        <Col md={8} xs={24}>
          <Select
            size="large"
            style={{ width: "100%" }}
            onSelect={handleTypeClick}
            className="m-activity"
            placeholder="Please select below"
            value={selectValue}
            defaultValue="">
            <Select.Option value={""}>-- Please select below --</Select.Option>
            {activities && (
              <>
                {activities.map((activity, index) => {
                  var niceName = activity.name.replace(/ /g, "").toLowerCase();
                  return (
                    <Select.Option
                      key={index}
                      disabled={!activity.available}
                      value={activity.name}
                      className={niceName}
                      data-id={activity.id}
                      data-slots={activity.slots}>
                      {activity.name} ({activity.slots} slot(s))
                    </Select.Option>
                  );
                })}
              </>
            )}
          </Select>
        </Col>
      </Row>
    </>
  ) : null;
}

export default ActivitySelect;
