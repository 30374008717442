import React, { useState, useEffect } from "react";
import { Layout, Row, Col, Form, Input, Switch, Button, Spin } from "antd";
import { Redirect, useParams } from "react-router-dom";
import * as WetwheelsApi from "../../../client-swagger/api";
import { useApp } from "../../../AppContext";
import { ApiResponse } from "../../../client-swagger/api";
import { Editor } from "@tinymce/tinymce-react";

const DashboardEmailConfigDetail = () => {
  const [redirect] = useState<boolean>(false);
  const [emailTemplate, setEmailTemplate] = useState(null);
  const { authHeaderOptions, notifyError, notifySuccess } = useApp();
  const [body, setBody] = useState();
  const { id } = useParams<any>();

  useEffect(() => {
    new WetwheelsApi.EmailApi()
      .getEmailTemplate(id, authHeaderOptions)
      .then(({ code, data, success, message }: ApiResponse) => {
        if (!success) {
          notifyError(message);
          return;
        }

        setEmailTemplate(data);
        setBody(data.body);
      });
  }, []);

  const handleSaveForm = async (values: WetwheelsApi.EmailTemplate) => {
    var newTempData: WetwheelsApi.EmailTemplate = values as WetwheelsApi.EmailTemplate;
    newTempData.body = body;

    const {
      success,
      message,
    } = await new WetwheelsApi.EmailApi().updateEmailTemplate(
      newTempData,
      id,
      authHeaderOptions
    );

    if (!success) {
      notifyError(message);
      return;
    }

    notifySuccess("Email template updated.");
  };

  const handleEditorChange = (content: any, editor: any) => {
    setBody(content);
  };

  return redirect ? (
    <Redirect to="/dashboard/emailtemplates/" />
  ) : (
    <>
      <Layout>
        <Layout.Header
          className="mb-m"
          style={{ padding: 0, background: "none", width: "100%" }}>
          <h2>Email Configuration</h2>
        </Layout.Header>

        <Layout.Content>
          <Spin spinning={!emailTemplate}>
            {emailTemplate && (
              <Row>
                <Col span={24}>
                  <Form
                    name="basic"
                    labelCol={{ xs: { span: 10 }, lg: { span: 6 } }}
                    wrapperCol={{ xs: { span: 14 }, lg: { span: 18 } }}
                    layout="horizontal"
                    onFinish={handleSaveForm}
                    size="large">
                    <Form.Item
                      label="Email key"
                      name="key"
                      initialValue={emailTemplate.key}>
                      <Input disabled placeholder="e.g ADMIN-CONFIRM" />
                    </Form.Item>
                    <Form.Item
                      label="Friendly name"
                      name="friendlyName"
                      initialValue={emailTemplate.friendlyName}>
                      <Input placeholder="please enter a friendly reference to the form" />
                    </Form.Item>
                    <Form.Item
                      label="Send from name"
                      name="sendFromName"
                      initialValue={emailTemplate.sendFromName}>
                      <Input placeholder="please enter a name that the email is sent from" />
                    </Form.Item>
                    <Form.Item
                      label="Send from email"
                      name="sendFromEmail"
                      initialValue={emailTemplate.sendFromEmail}>
                      <Input placeholder="please enter an email that the email is sent from" />
                    </Form.Item>
                    <Form.Item
                      label="Send to client"
                      name="shouldSendToClient"
                      valuePropName="checked"
                      initialValue={emailTemplate.shouldSendToClient}>
                      <Switch />
                    </Form.Item>
                    <Form.Item
                      label="Send to email"
                      name="sendToEmail"
                      initialValue={emailTemplate.sendToEmail}>
                      <Input placeholder="please enter emails that the email is sent to" />
                    </Form.Item>
                    <Form.Item
                      label="Email subject"
                      name="subject"
                      initialValue={emailTemplate.subject}>
                      <Input placeholder="please enter the emails subject" />
                    </Form.Item>

                    <Form.Item label="Email body">
                      <Editor
                        apiKey="f4f88amfkqqb4km00grjypj5s1srs86jech6y9kdlq2xqqns"
                        initialValue={body}
                        init={{
                          height: 500,
                          menubar: false,
                          plugins: [
                            "advlist autolink lists link image charmap print preview anchor",
                            "searchreplace visualblocks code fullscreen",
                            "insertdatetime media table paste code help wordcount",
                          ],
                          toolbar:
                            "undo redo | formatselect | bold italic backcolor | link | \
                            bullist numlist outdent indent | removeformat | help",
                        }}
                        onEditorChange={handleEditorChange}
                      />
                    </Form.Item>
                    <Form.Item
                      label="BCC address"
                      name="bccEmail"
                      initialValue={emailTemplate.bccEmail}>
                      <Input placeholder="please enter the BCC addresses for the email" />
                    </Form.Item>

                    <Button
                      type="primary"
                      className="a-button v-button-primary float-left mt-s"
                      htmlType="submit">
                      Save
                    </Button>
                  </Form>
                </Col>
              </Row>
            )}
          </Spin>
        </Layout.Content>
      </Layout>
    </>
  );
};

export default DashboardEmailConfigDetail;
