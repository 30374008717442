import React, { useState, useEffect } from "react";
import {
  Layout,
  Row,
  Col,
  Form,
  Input,
  Button,
  notification,
  Alert,
} from "antd";
import { useHistory } from "react-router-dom";

const GoToBooking = () => {
  const history = useHistory();
  const [key, setKey] = useState("");

  const handleGoToBooking = () => {
    const ref = `/dashboard/bookings/${key}`;
    history.push(ref);
  };

  return (
    <Row justify="start" className="mb-m">
      <Col xs={24}>
        <Row>
          <Col md={12}>
            <Input
              size="large"
              placeholder={"Reference e.g. A1B2C3"}
              onChange={(e) => {
                setKey(e.target.value);
              }}
            />
          </Col>
          <Col md={12}>
            <Button onClick={handleGoToBooking} type="primary" className="ml-s">
              Go to booking
            </Button>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default GoToBooking;
