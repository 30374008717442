import React, { useState, useEffect } from "react";
import User from "./enums/User";
import decodeJwt from "jwt-decode";
import useLocalStorage from "./hooks/useLocalStorageState";
import IBookingModel from "./interfaces/IBookingModel";
import IJwtToken from "./interfaces/IJwtToken";
import jwt from "jsonwebtoken";
import { notification } from "antd";
import AuthHeaderOptions from "./AuthHeaderOptions";
import moment from "moment";
import ICalendarEventDetails from "./interfaces/ICalendarEventDetails";
import { AddToCalendarEvent } from "react-add-to-calendar";
import { IPassengerAuto } from "./interfaces/IPassengerAuto";

const AppContext = React.createContext<IAppContext>(undefined);

function AppProvider({ children }: IAppProviderProps) {
  // auth
  const [jwtToken, setJwtToken] = useLocalStorage("JWT", "");
  const [isNotAuthenticated, setIsNotAuthenticated] = useState<boolean>(false);
  const [userName, setUserName] = useState<string>("");
  const [userRole, setUserRole] = useState<string>("");
  const [userId, setUserId] = useState<string>("");
  const [tokenExpired, setTokenExpired] = useState<boolean>(false);

  // app
  const [userType, setUserType] = useLocalStorage("userType", User.None);
  const [showAccept, setShowAccept] = useState<boolean>(true);
  const [redirectTo, setRedirectTo] = useState<string>(null);
  const [entryRoute, setEntryRoute] = useState<string>(null);
  const [selectedPassenger, setSelectedPassenger] = useState<IPassengerAuto>();

  const [manifest, setManifest] = useLocalStorage("manifest", false);
  const [disclaimer, setDisclaimer] = useLocalStorage("disclaimer", false);
  const [manifestData, setManifestData] = useState(null);

  const [bookingModel, setBookingModel] = useLocalStorage(
    "sessionBookingModel",
    null
  );

  const authHeaderOptions = AuthHeaderOptions(jwtToken);

  const [menuKey, setMenuKey] = useState<number>(0);

  const notifyError = (message: string) => {
    notification.error({
      message: "Error",
      description: message,
      duration: 3.0,
      onClose: () => {},
    });
  };

  const notifySuccess = (message: string) => {
    notification.success({
      message: "Success",
      description: message,
      duration: 3.0,
      onClose: () => {},
    });
  };

  const logOut = () => {
    //setIsAuthenticated(false);
    setUserRole(null);
    setUserName(null);
    setUserId(null);
    setJwtToken(null);
  };

  const createAddToCalEvent = (
    bookingData: ICalendarEventDetails,
    text?: any
  ) => {
    const event: AddToCalendarEvent = {
      title: `Wetwheels - ${bookingData.uniqueKey}: ${bookingData.firstName} ${bookingData.lastName} - ${bookingData.eventType}`,
      description: `   Passengers: ${bookingData.noOfPassengers}
      Wheelchair: ${bookingData.noOfWheelschairs}
      Non-wheelchair disabled: ${bookingData.noOfNonWheelchairDisabled}
      Additional information: ${bookingData.additionalInformation}
      Contact number: ${bookingData.contactNumber}
      Email: ${bookingData.email}
      Organisation: ${bookingData.organisation}`,
      location: "La Colette Marina, Jersey",
      startTime: moment(bookingData.startTime).format(),
      endTime: moment(bookingData.endTime).format(),
    };
    return event;
  };

  const isAuthenticated = () => {
    if (!jwtToken) {
      return;
    }

    async function checkToken(jwtToken: string): Promise<IJwtToken> {
      const decodedToken: IJwtToken = await decodeJwt(jwtToken);
      return decodedToken;
    }

    if (jwtToken) {
      checkToken(jwtToken).then((token) => {
        try {
          let token = jwt.decode(jwtToken);

          let exp = (token as any).exp;

          if (exp < (new Date().getTime() + 1) / 1000) {
            sessionStorage.clear();
            localStorage.clear();
            setTokenExpired(true);
            // setIsNotAuthenticated(true);
            window.location.assign("/login");
          }
          if ((token as any).role && (token as any).id) {
            setUserName((token as any).unique_name);
            setUserRole((token as any).role);
            setUserId((token as any).id);

            switch ((token as any).role) {
              case "client":
                setUserType(User.Client);
                break;
              case "skipper":
                setUserType(User.Skipper);
                break;
              case "crew":
                setUserType(User.Crew);
                break;
              case "admin":
                setUserType(User.Admin);
                break;
              default:
                setUserType(User.None);
                break;
            }
          } else {
            logOut();
            window.location.assign("/login");
          }
        } catch (err) {
          throw new Error(err);
        }
      });
    } else {
      // TODO logout function
      window.location.assign("/login");
      logOut();
    }
  };

  useEffect(() => {
    isAuthenticated();
  }, [jwtToken, tokenExpired]);

  const state: IAppContext = {
    jwtToken,
    setJwtToken,
    isNotAuthenticated,
    setIsNotAuthenticated,
    userName,
    setUserName,
    userRole,
    setUserRole,
    userId,
    setUserId,
    redirectTo,
    setRedirectTo,
    entryRoute,
    setEntryRoute,
    bookingModel,
    setBookingModel,
    userType,
    setUserType,
    manifest,
    setManifest,
    disclaimer,
    setDisclaimer,
    showAccept,
    setShowAccept,
    menuKey,
    setMenuKey,
    isAuthenticated,
    logOut,
    notifyError,
    notifySuccess,
    authHeaderOptions,
    createAddToCalEvent,
    manifestData,
    setManifestData,
    selectedPassenger,
    setSelectedPassenger,
  };

  return <AppContext.Provider value={state}>{children}</AppContext.Provider>;
}

function useApp() {
  const context = React.useContext(AppContext);

  if (context === undefined) {
    throw new Error("useApp must be used within a AppProvider");
  }

  return context;
}

export { AppProvider, useApp };

interface IAppProviderProps {
  children: React.ReactNode;
}

interface IAppContext {
  jwtToken: string;
  setJwtToken: (value: string) => void;
  isNotAuthenticated: boolean;
  setIsNotAuthenticated: (value: boolean) => void;
  redirectTo: string;
  setRedirectTo: (value: string) => void;
  userName: string;
  setUserName: (value: string) => void;
  userRole: string;
  setUserRole: (value: string) => void;
  userId: string;
  setUserId: (value: string) => void;
  entryRoute: string;
  setEntryRoute: (value: string) => void;
  bookingModel: IBookingModel;
  setBookingModel: (value: IBookingModel) => void;
  userType: User;
  setUserType: (value: User) => void;
  showAccept: boolean;
  setShowAccept: (value: boolean) => void;
  menuKey: number;
  disclaimer: boolean;
  setDisclaimer: (value: boolean) => void;
  manifest: boolean;
  setManifest: (value: boolean) => void;
  setMenuKey: (value: number) => void;
  isAuthenticated: () => any;
  logOut: () => void;
  notifyError: (message: string) => void;
  notifySuccess: (message: string) => void;
  authHeaderOptions: any;
  createAddToCalEvent: (bookingDate: any, text?: any) => AddToCalendarEvent;
  manifestData: any;
  setManifestData: (value: any) => void;
  selectedPassenger: any;
  setSelectedPassenger: (value: any) => void;
}
