import React, { useEffect } from "react";
import { DatePicker, Form, Input, Modal, Select, Switch } from "antd";
import moment from "moment";
import { useApp } from "../../../../AppContext";
import { FormInstance } from "antd/lib/form";

interface IManifestAddOrUpdate {
  showAddPassengerModal: boolean;
  handleSavePassenger: (values: any) => void;
  handleCancelSavePassenger: () => void;
}

const ManifestAddOrUpdate = ({
  showAddPassengerModal,
  handleSavePassenger,
  handleCancelSavePassenger,
}: IManifestAddOrUpdate) => {
  const { selectedPassenger } = useApp();
  const [addPassengerForm] = Form.useForm();

  useEffect(() => {
    addPassengerForm.resetFields();
  }, [selectedPassenger]);

  return (
    <Modal
      title={`${selectedPassenger ? "Update" : "Add"} Passenger`}
      visible={showAddPassengerModal}
      okText="Save passenger"
      cancelText="Cancel"
      //okButtonProps={{htmlType: 'submit', form: 'editPassengerForm'}}
      onCancel={() => {
        addPassengerForm.resetFields();
        handleCancelSavePassenger();
      }}
      onOk={() => {
        addPassengerForm
          .validateFields()
          .then((values) => {
            addPassengerForm.resetFields();
            handleSavePassenger(values);
          })
          .catch((info) => {
            throw new Error(info);
          });
      }}
      width="85vw"
    >
      <Form
        id="editPassengerForm"
        labelCol={{ sm: { span: 24 }, lg: { span: 10 } }}
        wrapperCol={{ sm: { span: 24 }, lg: { span: 14 } }}
        layout="horizontal"
        form={addPassengerForm}
        initialValues={{
          firstName: selectedPassenger ? selectedPassenger.firstName : "",
          lastName: selectedPassenger ? selectedPassenger.lastName : "",
          isWheelchairUser: selectedPassenger
            ? selectedPassenger.isWheelchairUser
            : false,
          isDisabledNonWheelchair: selectedPassenger
            ? selectedPassenger.isDisabledNonWheelchair
            : false,
          hasGivenPhotoConsent: selectedPassenger
            ? selectedPassenger.hasGivenPhotoConsent
            : false,
          dateOfBirth: selectedPassenger
            ? moment(selectedPassenger.dateOfBirth)
            : undefined,
          isRepeatUser: selectedPassenger
            ? selectedPassenger.isRepeatUser
            : false,
          gender: selectedPassenger ? selectedPassenger.gender : "",
        }}
      >
        <Form.Item
          label="First name"
          name="firstName"
          rules={[
            {
              required: true,
              message: "Please add your first name",
            },
          ]}
        >
          <Input size="large" />
        </Form.Item>
        <Form.Item
          label="Last name"
          name="lastName"
          rules={[
            {
              required: true,
              message: "Please add your last name",
            },
          ]}
        >
          <Input size="large" />
        </Form.Item>
        <Form.Item
          label="Is wheelchair user?"
          name="isWheelchairUser"
          valuePropName="checked"
        >
          <Switch />
        </Form.Item>
        <Form.Item
          label="Is special needs user?"
          name="isDisabledNonWheelchair"
          valuePropName="checked"
        >
          <Switch />
        </Form.Item>
        <p>
          Because of the changes in data protection laws, we treat photographs
          very carefully. As a result, we have to ask your permission to use the
          photographs and want you to be clear on where and how we use them.
        </p>
        <p>
          By selecting Yes below, you grant Wetwheels Jersey, Wetwheels
          Operators and the Wetwheels Foundation, permission to take photographs
          and/or video of your group/family and full rights to use the images
          resulting from the photography/video filming, and any reproductions or
          adaptations of the images on our social media pages, including for
          fundraising, publicity or other purposes to help achieve the group’s
          aims. This might include (but is not limited to), the right to use
          them in our printed and online publicity, social media, press releases
          and funding applications.
        </p>
        <Form.Item
          label="Do you authorise us to use photos?"
          name="hasGivenPhotoConsent"
          valuePropName="checked"
        >
          <Switch />
        </Form.Item>
        <Form.Item
          label="Date of birth"
          name="dateOfBirth"
          rules={[
            {
              required: true,
              message: "Please select date of birth",
            },
          ]}
        >
          <DatePicker size="large" placeholder="YYYY-MM-DD" />
        </Form.Item>
        <Form.Item
          label="Gender"
          name="gender"
          rules={[
            {
              required: true,
              message: "Please select gender",
            },
          ]}
        >
          <Select
            size="large"
            value={selectedPassenger ? selectedPassenger.gender : ""}
          >
            <Select.Option value="Male">Male</Select.Option>
            <Select.Option value="Female">Female</Select.Option>
            <Select.Option value="Unspecified">Not specified</Select.Option>
          </Select>
        </Form.Item>

        <Form.Item
          label="Has this person used Wetwheels before?"
          name="isRepeatUser"
          valuePropName="checked"
        >
          <Switch />
        </Form.Item>
      </Form>
    </Modal>
  );
};
export default ManifestAddOrUpdate;
