import React, { useEffect } from "react";
import { RouteComponentProps } from "react-router-dom";
import DashboardLayout from "./dashboardLayout";
import DashboardBookings from "./Booking/dashboardBookings";
import DashboardAvailablity from "./Availability/dashboardAvailablity";
import { useApp } from "../../AppContext";
import User from "../../enums/User";
import DashboardHome from "./dashboardHome";
import DashboardSkippers from "./Skippers/dashboardSkippers";
import DashboardBookingsDetail from "./Booking/Common/detail";
import DashboardBookingsDetailAdmin from "./Booking/Admin/detail";
import DashboardClients from "./Clients/dashboardClients";
import DashboardCrew from "./Crew/dashboardCrew";
import DashboardAdmins from "./Admins/dashboardAdmins";
import DashboardBookingClientManifest from "./Booking/Common/manifest";
import ManifestPrint from "./Booking/Common/manifestPrint";
import DashboardBookingClientDisclaimer from "./Booking/Client/disclaimer";
import DashboardAccount from "./Account";
import DashboardEmailConfig from "./Email";
import DashboardEmailQueue from "./EmailQueue";
import DashboardEmailConfigDetail from "./Email/DashboardEmailConfigDetail";
import DashboardReports from "./Reports";
import decodeJwt from "jwt-decode";
import IJwtToken from "../../interfaces/IJwtToken";
import PrivateRoute from "../../components/Util/PrivateRoute";

import EventTypeList from "./EventTypes/eventTypeList";

function DashboardRoute({ match }: RouteComponentProps) {
  const { userType, setUserType, jwtToken } = useApp();

  useEffect(() => {
    if (!jwtToken) {
      return;
    }

    async function checkToken(jwtToken: string): Promise<IJwtToken> {
      const decodedToken: IJwtToken = await decodeJwt(jwtToken);
      return decodedToken;
    }

    if (jwtToken) {
      checkToken(jwtToken).then((token) => {
        const role = token.role;
        switch (role) {
          case "client":
            setUserType(User.Client);
            break;
          case "skipper":
            setUserType(User.Skipper);
            break;
          case "admin":
            setUserType(User.Admin);
            break;
          case "crew":
            setUserType(User.Crew);
            break;
          default:
            setUserType(User.None);
            break;
        }
      });
    }
  }, []);

  return (
    <>
      <DashboardLayout>
        <PrivateRoute exact path={`${match.path}`} component={DashboardHome} />

        {userType === User.Skipper && (
          <>
            <PrivateRoute
              exact
              path={`${match.path}/availability`}
              component={DashboardAvailablity}
            />
          </>
        )}

        {userType === User.Admin && (
          <>
            <PrivateRoute
              exact
              path={`${match.path}/emailtemplates`}
              component={DashboardEmailConfig}
            />
            <PrivateRoute
              exact
              path={`${match.path}/emailtemplates/:id`}
              component={DashboardEmailConfigDetail}
            />
            <PrivateRoute
              exact
              path={`${match.path}/emailqueue`}
              component={DashboardEmailQueue}
            />
            <PrivateRoute
              path={`${match.path}/eventtypes`}
              component={EventTypeList}
            />
            <PrivateRoute
              exact
              path={`${match.path}/reports`}
              component={DashboardReports}
            />
            <PrivateRoute
              exact
              path={`${match.path}/skippers`}
              component={DashboardSkippers}
            />
            <PrivateRoute
              exact
              path={`${match.path}/crew`}
              component={DashboardCrew}
            />
            <PrivateRoute
              exact
              path={`${match.path}/crew/:id`}
              component={DashboardCrew}
            />
            <PrivateRoute
              exact
              path={`${match.path}/clients`}
              component={DashboardClients}
            />
            <PrivateRoute
              exact
              path={`${match.path}/clients/:id`}
              component={DashboardClients}
            />
            <PrivateRoute
              exact
              path={`${match.path}/admins`}
              component={DashboardAdmins}
            />
            <PrivateRoute
              exact
              path={`${match.path}/availability/:skipperId`}
              component={DashboardAvailablity}
            />
          </>
        )}
        <PrivateRoute
          exact
          path={`${match.path}/bookings/:id`}
          component={
            userType == User.Admin
              ? DashboardBookingsDetailAdmin
              : DashboardBookingsDetail
          }
        />
        <PrivateRoute
          exact
          path={`${match.path}/account`}
          component={DashboardAccount}
        />
        <PrivateRoute
          exact
          path={`${match.path}/bookings/:id/manifest`}
          component={DashboardBookingClientManifest}
        />
        <PrivateRoute
          exact
          path={`${match.path}/bookings/:id/print`}
          component={ManifestPrint}
        />
        <PrivateRoute
          exact
          path={`${match.path}/bookings`}
          component={DashboardBookings}
        />
        <PrivateRoute
          exact
          path={`${match.path}/bookings/:id/disclaimer`}
          component={DashboardBookingClientDisclaimer}
        />
      </DashboardLayout>
    </>
  );
}

export default DashboardRoute;
