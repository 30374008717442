import React, { useState, useEffect } from "react";
import { Layout, Drawer, Row, Col } from "antd";
import { useParams, useHistory } from "react-router-dom";
import * as WetwheelsApi from "../../../client-swagger/api";
import { useApp } from "../../../AppContext";
import UserList from "../../../components/User/List/userList";
import DashboardCrewAdd from "./dashboardCrewAdd";
import DashboardCrewDetail from "./dashboardCrewDetail";

function DashboardCrew() {
  const { authHeaderOptions, notifyError } = useApp();
  const history = useHistory();

  const [isCrewListLoading, setIsCrewListLoading] = useState<boolean>(true);
  const [crewList, setCrewList] = useState();

  const [data, setData] = useState(null);
  const [drawerVisible, setDrawerVisible] = useState<boolean>(false);
  const [drawerAddVisible, setDrawerAddVisible] = useState<boolean>(false);

  const { id } = useParams() as any;

  let disable = false;

  const onClose = () => {
    setData(null);
    setDrawerVisible(false);
    setDrawerAddVisible(false);
    fetchList();
  };

  const handleNewCrewClick = () => {
    setDrawerVisible(false);
    setDrawerAddVisible(true);
  };

  const handleRow = (record: any) => {
    return {
      onClick: () => {
        if (disable) return;
        if (record) {
          setData(record);
        }
        setDrawerAddVisible(false);
        setDrawerVisible(true);
      },
    };
  };

  const fetchList = async () => {
    setIsCrewListLoading(true);
    const {
      data,
      success,
      message,
    } = await new WetwheelsApi.CrewApi().getAllCrew(authHeaderOptions);

    if (!success) {
      notifyError(message);
      return;
    }

    setCrewList(
      data.map((c: any) => {
        let user = c.user;
        user.crewId = c.id;
        return user;
      })
    );
    setIsCrewListLoading(false);
  };

  const fetchCrew = async (id: string) => {
    const { data, success } = await new WetwheelsApi.UserApi().user(
      id,
      authHeaderOptions
    );
    if (!success) {
      notifyError("Unable to load crew");
      return;
    }

    if (data) {
      setData(data);
      setDrawerAddVisible(false);
      setDrawerVisible(true);
    }
  };

  useEffect(() => {
    fetchList();

    const loadCrew = async (id: string) => {
      await fetchCrew(id);
    };
    if (id) {
      loadCrew(id);
    }
  }, []);

  return (
    <>
      <Layout>
        <Layout.Header
          className="mb-m v-ant-layout-header"
          style={{ padding: 0, background: "none", width: "100%" }}>
          <Row justify="space-between" align="middle">
            <Col>
              <h2 className="mr-m">Crew</h2>
            </Col>
            <Col>
              <button
                className="a-button v-button-primary"
                onClick={handleNewCrewClick}>
                Add new crew
              </button>
            </Col>
          </Row>
        </Layout.Header>
        <Layout.Content>
          <UserList
            bindData={fetchList}
            handleRowClick={handleRow}
            isLoading={isCrewListLoading}
            list={crewList}></UserList>
        </Layout.Content>
      </Layout>
      <Drawer
        width={"75vw"}
        onClose={onClose}
        visible={drawerVisible}
        bodyStyle={{ paddingBottom: 80 }}>
        <DashboardCrewDetail user={data} closeWindow={onClose} />
      </Drawer>

      <Drawer
        width={"75vw"}
        onClose={onClose}
        visible={drawerAddVisible}
        bodyStyle={{ paddingBottom: 80 }}>
        <DashboardCrewAdd closeWindow={onClose} />
      </Drawer>
    </>
  );
}

export default DashboardCrew;
