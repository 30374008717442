import React from "react";
import { Steps } from "antd";
import { useBooking } from "./bookingContext";

const { Step } = Steps;

function BookingProgress() {
  const { step } = useBooking();

  // const checkWindowWidth = () => {
  //   return window.innerWidth < 700 ? "vertical" : "horizontal";
  // };

  // useEffect(() => {
  //   checkWindowWidth();
  // }, [window.innerWidth]);

  return (
    <>
      <Steps
        current={step}
        // direction={checkWindowWidth()}
        direction="vertical"
        className="mt-m mb-l ant-steps-responsive">
        <Step title="Setup your trip" />
        <Step title="Login or register" />
        <Step title="Review your trip" />
        <Step title="Complete" />
      </Steps>
    </>
  );
}

export default BookingProgress;
