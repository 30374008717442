import React, { useEffect, useState } from "react";
import useLocalStorage from "../../../../hooks/useLocalStorageState";
import { Layout, Row, Col, Table, Select } from "antd";
import moment from "moment";
import { useHistory } from "react-router-dom";

import * as WetwheelsApi from "../../../../client-swagger/api";
import { useApp } from "../../../../AppContext";
import { Breakpoint } from "antd/lib/_util/responsiveObserve";

import GoToBooking from "../Common/goToBooking";

// main column data and handlers
const columns = [
  {
    title: "Name",
    dataIndex: "firstName",
    key: "firstName",
    responsive: ["sm"] as Breakpoint[],
    render: (text: string, record: any) => {
      return record.firstName + " " + record.lastName;
    },
    sorter: (a: any, b: any) => {
      return a.firstName.localeCompare(b.firstName) as number;
    },
  },
  {
    title: "Organisation",
    dataIndex: "organisation",
    key: "organisation",
    responsive: ["lg"] as Breakpoint[],
    sorter: (a: any, b: any) => {
      return a.organisation.localeCompare(b.organisation) as number;
    },
  },
  {
    title: "Skipper",
    dataIndex: "skipper",
    key: "skipper",
    responsive: ["lg"] as Breakpoint[],
    render: (text: string, record: any) => {
      return record.skipperFirstName + " " + record.skipperLastName;
    },
    sorter: (a: any, b: any) => {
      return a.skipper.localeCompare(b.skipper) as number;
    },
  },
  {
    title: "Crew",
    dataIndex: "crew",
    key: "crew",
    responsive: ["lg"] as Breakpoint[],
    render: (text: string, record: any) => {
      return record.crewFirstName + " " + record.crewLastName;
    },
    sorter: (a: any, b: any) => {
      return a.crew.localeCompare(b.crew) as number;
    },
  },
  {
    title: "Activity",
    dataIndex: "activityName",
    key: "activityName",
    sorter: (a: any, b: any) => {
      return a.activityName.localeCompare(b.activityName) as number;
    },
  },
  {
    title: "Reference",
    dataIndex: "uniqueKey",
    key: "uniqueKey",
    sorter: (a: any, b: any) => {
      return a.uniqueKey.localeCompare(b.uniqueKey) as number;
    },
  },
  {
    title: "Number of People / Wheelchairs",
    dataIndex: "numberOfPassengers",
    key: "numberOfPassengers",
    responsive: ["md"] as Breakpoint[],
    render: (text: string, record: any) => {
      return `${record.numberOfPassengers} / ${record.numberOfWheelchairs}`;
    },
  },
  {
    title: "Date",
    dataIndex: "date",
    key: "date",
    render: (text: string, record: any) => {
      return `${moment(text).format("dddd")}, ${moment(text).format(
        "LL"
      )}: ${moment(record.startDateTime).format("LT")} to ${moment(
        record.endDateTime
      ).format("LT")}`;
    },
    sorter: (a: any, b: any) => {
      return moment(a).isBefore(moment(b)) ? -1 : 1;
    },
  },
];

const DashboardBookingsAdmin = () => {
  const { authHeaderOptions } = useApp();
  const [unconfirmedListLoading, setUnconfirmedListLoading] =
    useState<boolean>(false);
  const [unconfirmedList, setUnconfirmedList] = useState();
  const [confirmedListLoading, setConfirmedListLoading] =
    useState<boolean>(false);
  const [confirmedList, setConfirmedList] = useState();
  const [cancelledListLoading, setCancelledListLoading] =
    useState<boolean>(false);
  const [cancelledList, setCancelledList] = useState();
  const history = useHistory();

  const [startDate, setStartDate] = useLocalStorage(
    "_bookings_startDate",
    moment().startOf("isoWeek").toDate()
  );

  const [filterAllBookings, setFilterAllBookings] = useLocalStorage(
    "_bookings_filterAll",
    false
  );
  const [filterFutureBookings, setFilterFutureBookings] = useLocalStorage(
    "_bookings_filterFuture",
    false
  );

  const [filterListDates, setFilterListDates] = useState(null);

  const [, setIsLoading] = useState(true);

  const handleFilterChange = (value: any) => {
    if (value) {
      setIsLoading(true);

      if (value === "ALL") {
        setStartDate(undefined);
        setFilterAllBookings(true);
        setFilterFutureBookings(false);
      } else if (value === "FUTURE") {
        setStartDate(undefined);
        setFilterAllBookings(false);
        setFilterFutureBookings(true);
      } else {
        setFilterAllBookings(false);
        setFilterFutureBookings(false);
        setStartDate(moment(value).toDate());
      }
    }
  };

  const getStartDate = async () => {
    const date = filterAllBookings
      ? moment(filterListDates[0]).format()
      : filterFutureBookings
      ? moment().format()
      : startDate
      ? moment(startDate).format()
      : moment().format();

    console.log("date", date);
    return date;
  };

  const getDuration = async () => {
    const length = filterAllBookings || filterFutureBookings ? 9999 : 7;
    console.log("length", length);
    return length;
  };

  const fetchUnconfirmedBookings = async () => {
    setUnconfirmedListLoading(true);

    const { code, data } =
      await new WetwheelsApi.BookingApi().listBookingsByStatus(
        "Unconfirmed",
        await getStartDate(),
        await getDuration(),
        authHeaderOptions
      );

    setUnconfirmedListLoading(false);
    if (code !== 200) {
    }
    setUnconfirmedList(data);
  };

  const fetchConfirmedBookings = async () => {
    setConfirmedListLoading(true);

    const { code, data } =
      await new WetwheelsApi.BookingApi().listBookingsByStatus(
        "Confirmed",
        await getStartDate(),
        await getDuration(),
        authHeaderOptions
      );

    setConfirmedListLoading(false);

    if (code !== 200) return;
    setConfirmedList(data);
  };

  const fetchCancelledBookings = async () => {
    setCancelledListLoading(true);

    const { success, data } =
      await new WetwheelsApi.BookingApi().listBookingsByStatus(
        "Cancelled",
        await getStartDate(),
        await getDuration(),
        authHeaderOptions
      );

    setCancelledListLoading(false);

    if (!success) return;
    setCancelledList(data);
  };

  const fetchMinMaxDates = async () => {
    const { success, data } =
      await new WetwheelsApi.BookingApi().bookingMinMaxDatesGet(
        authHeaderOptions
      );

    if (!success) {
      return;
    }

    const min = moment(data.minDate).startOf("isoWeek");
    const max = moment(data.maxDate).startOf("isoWeek");

    let start = min;
    let dates = [];
    while (start <= max) {
      dates.push(start.toDate());
      start = start.add(7, "days");
    }

    setFilterListDates(dates);
  };

  useEffect(() => {
    const startUp = async () => {
      // await Promise.all([
      //   fetchUnconfirmedBookings,
      //   fetchConfirmedBookings,
      //   fetchCancelledBookings,
      // ]);
      await fetchMinMaxDates();
      setIsLoading(false);
    };

    startUp();
  }, []);

  useEffect(() => {
    const startUp = async () => {
      await fetchUnconfirmedBookings();
      await fetchConfirmedBookings();
      await fetchCancelledBookings();
      setIsLoading(false);
    };

    if (filterListDates) {
      startUp();
    }
  }, [filterListDates]);

  useEffect(() => {
    const refresh = async () => {
      setIsLoading(true);
      await fetchUnconfirmedBookings();
      await fetchConfirmedBookings();
      await fetchCancelledBookings();
      setIsLoading(false);
    };

    if (filterListDates) {
      refresh();
    }
  }, [startDate, filterAllBookings, filterFutureBookings]);

  return (
    <>
      <Layout>
        <Layout.Header
          className="mb-xl v-ant-layout-header"
          style={{ padding: 0, background: "none", width: "100%" }}
        >
          <Row justify="space-around" className="m-small-screen-stack">
            <Col span={24}>
              <h2>Bookings</h2>
            </Col>

            <Col span={12}>
              {filterListDates && (
                <Row>
                  <Col xs={24} md={12} lg={6}>
                    Week commencing:{" "}
                  </Col>
                  {/* TODO Sort out filtering and showing the bookings in that week */}
                  <Col lg={12} xs={24}>
                    <Select
                      style={{ width: 300 }}
                      defaultValue={
                        filterAllBookings
                          ? "ALL"
                          : filterFutureBookings
                          ? "FUTURE"
                          : startDate
                          ? moment(startDate).startOf("isoWeek").format()
                          : "ALL"
                      }
                      onChange={handleFilterChange}
                    >
                      <Select.Option value="FUTURE">
                        FUTURE BOOKINGS
                      </Select.Option>
                      <Select.Option value="ALL">ALL BOOKINGS</Select.Option>
                      <Select.Option
                        key={`thisweek-${new Date().toISOString()}`}
                        value={moment().startOf("isoWeek").format()}
                      >
                        THIS WEEK'S BOOKINGS
                      </Select.Option>
                      {filterListDates.map((date: Date) => (
                        <Select.Option
                          key={date.toISOString()}
                          value={moment(date).format()}
                        >
                          {moment(date).startOf("isoWeek").format("DD/MM/YYYY")}
                        </Select.Option>
                      ))}
                    </Select>
                  </Col>
                </Row>
              )}
            </Col>
            <Col span={12}>
              <GoToBooking />
            </Col>
          </Row>
        </Layout.Header>
        <Layout.Content>
          <Row gutter={[16, 16]}>
            <Col md={{ span: 24 }}>
              {/* <h4>
                {allTime
                  ? "VIEWING ALL BOOKINGS"
                  : "Week commencing - " +
                    moment(startDate).format("DD/MM/YYYY")}
              </h4> */}

              <div className="mb-m">
                <h4>Unconfirmed bookings</h4>
                <Table
                  onRow={(record: any) => {
                    return {
                      onClick: () => {
                        const ref = `/dashboard/bookings/${record.bookingId}`;
                        history.push(ref);
                      },
                    };
                  }}
                  size="small"
                  rowKey={(record) => record.id}
                  columns={columns}
                  dataSource={unconfirmedList}
                  loading={unconfirmedListLoading}
                />
              </div>
              <div className="mb-m">
                <h4>Confirmed bookings</h4>
                <Table
                  onRow={(record) => {
                    return {
                      onClick: () => {
                        const ref = `/dashboard/bookings/${record.bookingId}`;
                        history.push(ref);
                      },
                    };
                  }}
                  size="small"
                  rowKey={(record) => record.id}
                  columns={columns}
                  dataSource={confirmedList}
                  loading={confirmedListLoading}
                />
              </div>
              <div className="mb-m">
                <h4>Cancelled bookings</h4>
                <Table
                  onRow={(record) => {
                    return {
                      onClick: () => {
                        const ref = `/dashboard/bookings/${record.bookingId}`;
                        history.push(ref);
                      },
                    };
                  }}
                  size="small"
                  rowKey={(record) => record.id}
                  columns={columns}
                  dataSource={cancelledList}
                  loading={cancelledListLoading}
                />
              </div>
            </Col>
          </Row>
        </Layout.Content>
        {/* <Layout.Footer>
          <Space size="large">
             <Button className="a-button v-button-primary float-left">
              <Link to={`/dashboard/bookings/detail`}>Add Booking</Link>
            </Button>
         
          </Space>
        </Layout.Footer> */}
      </Layout>
    </>
  );
};

export default DashboardBookingsAdmin;
