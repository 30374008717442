import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Space,
  notification,
  Switch,
  Layout,
  DatePicker,
  Form,
  Button,
  Table,
  Spin,
} from "antd";
import { CheckOutlined } from "@ant-design/icons";
import moment, { Moment } from "moment";
import { Link } from "react-router-dom";

import * as WetwheelsApi from "../../../client-swagger/api";
import { CreateSkipper } from "../../../client-swagger/api";
import { useApp } from "../../../AppContext";
import { ApiResponse } from "../../../client-swagger/api";
import Availability from "../../../enums/Availability";
import AuthHeaderOptions from "../../../AuthHeaderOptions";
import { useParams } from "react-router-dom";

export interface IDayAvailability {
  dayName: string;
  availability: Availability;
}

function DashboardAvailablity() {
  const { jwtToken, userRole, notifySuccess, notifyError } = useApp();

  const [
    isUnavailableListLoading,
    setIsUnavailableListLoading,
  ] = useState<boolean>(true);
  const [unavailableDates, setUnavailableDates] = useState(null);
  const [unavailableDay, setUnavailableDay] = useState<moment.Moment>(
    moment(new Date(), "DD/MM/YYYY")
  );

  const [dayAvailability, setDayAvailability] = useState<IDayAvailability[]>(
    []
  );

  const [skipperUser, setSkipperUser] = useState(null);
  const { skipperId } = useParams<any>();

  const authHeaderOptions = AuthHeaderOptions(jwtToken);

  const handleChange = (
    dayName: string,
    amOrPm: Availability,
    isActive: boolean
  ) => {
    let day = dayAvailability.find((s) => s.dayName === dayName);
    if (!day) {
      notifyError("Could not update " + dayName);
    }
    let dayIndex = dayAvailability.findIndex((s) => s.dayName === dayName);
    const newAvailability = calcNewAvailability(day, amOrPm, isActive);
    dayAvailability[dayIndex] = newAvailability;

    setDayAvailability(dayAvailability);

    // this is working well.
    // now need to update

    // woprking within confines of API
    const skipperInfo: CreateSkipper = {
      contactNumber: skipperUser ? skipperUser.contactNumber : "",
      email: skipperUser ? skipperUser.email : "",
      firstName: skipperUser ? skipperUser.firstName : "",
      lastName: skipperUser ? skipperUser.lastName : "",
      availabilty: {
        isAvailableMonday: dayAvailability.find((s) => s.dayName === "Monday")
          .availability,
        isAvailableTuesday: dayAvailability.find((s) => s.dayName === "Tuesday")
          .availability,
        isAvailableWednesday: dayAvailability.find(
          (s) => s.dayName === "Wednesday"
        ).availability,
        isAvailableThursday: dayAvailability.find(
          (s) => s.dayName === "Thursday"
        ).availability,
        isAvailableFriday: dayAvailability.find((s) => s.dayName === "Friday")
          .availability,
        isAvailableSaturday: dayAvailability.find(
          (s) => s.dayName === "Saturday"
        ).availability,
        isAvailableSunday: dayAvailability.find((s) => s.dayName === "Sunday")
          .availability,
      },
    };

    new WetwheelsApi.SkipperApi()
      .skipperPatch(skipperInfo, skipperId, authHeaderOptions)
      .then(async ({ code, data, success, message }: ApiResponse) => {
        if (!success) {
          notifyError(message);
          return;
        }
        // local data is already up to date
        notifySuccess(message);
      });
  };

  const calcNewAvailability = (
    day: IDayAvailability,
    amOrPm: Availability,
    isActive: boolean
  ) => {
    let dayAvailability = day.availability;

    let isAvailableAM: boolean =
      dayAvailability === Availability.AM ||
      dayAvailability === Availability.BOTH;
    let isAvailablePM: boolean =
      dayAvailability === Availability.PM ||
      dayAvailability === Availability.BOTH;

    if (amOrPm === Availability.AM) {
      isAvailableAM = isActive;
    }

    if (amOrPm === Availability.PM) {
      isAvailablePM = isActive;
    }

    if (isAvailableAM && isAvailablePM) {
      dayAvailability = Availability.BOTH;
    } else if (!isAvailableAM && isAvailablePM) {
      dayAvailability = Availability.PM;
    } else if (isAvailableAM && !isAvailablePM) {
      dayAvailability = Availability.AM;
    } else if (!isAvailableAM && !isAvailablePM) {
      dayAvailability = Availability.NONE;
    }

    return {
      dayName: day.dayName,
      availability: dayAvailability,
    };
  };

  // DATES / GOOD
  const handleRemoveClick = async (record: any) => {
    const {
      success,
      message,
    }: ApiResponse = await new WetwheelsApi.SkipperApi().skipperUnavailableDelete(
      record.id,
      authHeaderOptions
    );

    if (!success) {
      notifyError(message);
    }

    bindUnavailableDates(skipperId);
    notifySuccess(message);
  };

  const handleAddDateClick = () => {
    let day = unavailableDay.toDate().toJSON();

    let daysOff: string[] = [];

    unavailableDates.forEach((data: { date: string }) => {
      daysOff.push(data.date.substring(0, 10));
    });

    daysOff.includes(day.substring(0, 10))
      ? notification.error({
          message: "This date has already been added",
          description:
            "Please select from the date picker if you would like to add another date",
        })
      : new WetwheelsApi.SkipperApi()
          .skipperUnavailablePost(day, skipperId, authHeaderOptions)
          .then(async ({ code, data, success, message }: ApiResponse) => {
            if (!success) {
              notifyError(message);
            }

            bindUnavailableDates(skipperId);

            notification.open({
              message: "Your availability has been saved",
              description:
                "You will be excluded from any new bookings that happen on this date",
              icon: <CheckOutlined style={{ color: "#108ee9" }} />,
            });
          });
  };

  const columns = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (text: string) => {
        return moment(text).format("LL");
      },
    },
    {
      title: "Delete",
      dataIndex: "delete",
      key: "delete",
      render: (text: any, record: any) => (
        <>
          <button
            onClick={() => handleRemoveClick(record)}
            className="a-button v-button-warning">
            Remove
          </button>
        </>
      ),
      width: "180px",
    },
  ];

  const bindUnavailability = (skipperId: string) => {
    new WetwheelsApi.SkipperApi()
      .skipperGet(skipperId, authHeaderOptions) // null as uses current user id
      .then(async ({ code, data, success, message }: ApiResponse) => {
        if (!success) {
          notifyError(message);
          return;
        }

        setSkipperUser(data.user);

        const availabilityLoaded: IDayAvailability[] = [
          { dayName: "Monday", availability: data.monday as Availability },
          { dayName: "Tuesday", availability: data.tuesday as Availability },
          {
            dayName: "Wednesday",
            availability: data.wednesday as Availability,
          },
          { dayName: "Thursday", availability: data.thursday as Availability },
          { dayName: "Friday", availability: data.friday as Availability },
          { dayName: "Saturday", availability: data.saturday as Availability },
          { dayName: "Sunday", availability: data.sunday as Availability },
        ];

        setDayAvailability(availabilityLoaded);
      });
  };

  const bindUnavailableDates = (skipperId: string) => {
    new WetwheelsApi.SkipperApi()
      .skipperUnavailableGet(skipperId, authHeaderOptions)
      .then(async ({ code, data, success, message }: ApiResponse) => {
        setIsUnavailableListLoading(false);

        if (!success) {
          notifyError(message);
          return;
        }

        setUnavailableDates(data);
      });
  };

  function disabledDate(current: Moment) {
    // Can not select days before today and today
    return current && current <= moment().startOf("day");
  }

  useEffect(() => {
    // setAdminSkipperId(skipperId);
    // get the data for that skipper and parse to fill in the checkboxes
    bindUnavailability(skipperId);
    bindUnavailableDates(skipperId);
  }, [skipperId]);

  return (
    <>
      <Layout>
        <Layout.Header
          className="mb-m v-ant-layout-header"
          style={{ padding: 0, background: "none", width: "100%" }}>
          <h2>
            {userRole === "skipper" ? "Your " : ""}
            Availability{" "}
            {userRole === "admin" && skipperUser
              ? `for ${skipperUser.firstName} ${skipperUser.lastName}`
              : ""}
          </h2>
          <p>
            {userRole === "skipper"
              ? "This is your availability on a standard week. Click on the switches  under AM and PM to make changes"
              : "You an amend the Skipper's availability below"}
          </p>
        </Layout.Header>

        <Layout.Content>
          <Row gutter={[16, 52]}>
            <Col span={24}>
              <Spin spinning={!dayAvailability}>
                <Space direction="vertical" size="large">
                  {dayAvailability
                    ? dayAvailability.map((day) => (
                        <Row key={day.dayName}>
                          <Col xs={24} md={18} lg={16} className="mt-s">
                            <Row key={day.dayName}>
                              <Col xs={{ span: 4, offset: 16 }}>
                                <p>AM</p>
                              </Col>
                              <Col span={4}>
                                <p>PM</p>
                              </Col>
                            </Row>
                            <Row>
                              <Col span={16}>
                                <p>{day.dayName}</p>
                              </Col>
                              <Col span={4}>
                                <Switch
                                  defaultChecked={
                                    day.availability === Availability.AM ||
                                    day.availability === Availability.BOTH
                                  }
                                  onChange={(checked) =>
                                    handleChange(
                                      day.dayName,
                                      Availability.AM,
                                      checked
                                    )
                                  }
                                />
                              </Col>
                              <Col span={4}>
                                <Switch
                                  defaultChecked={
                                    day.availability === Availability.PM ||
                                    day.availability === Availability.BOTH
                                  }
                                  onChange={(checked) =>
                                    handleChange(
                                      day.dayName,
                                      Availability.PM,
                                      checked
                                    )
                                  }
                                />
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      ))
                    : null}
                </Space>
              </Spin>
            </Col>
          </Row>
        </Layout.Content>

        <Layout.Content>
          <Row gutter={[16, 52]} className="mt-l">
            <h4>Unavailable on these days</h4>
          </Row>
          <Row gutter={[16, 52]}>
            <p>
              Add exceptions to {userRole === "skipper" ? "your" : "their"}{" "}
              standard availability here
            </p>
          </Row>

          <Row gutter={[16, 16]}>
            <Col>
              <Form labelCol={{ span: 8 }} wrapperCol={{ span: 14 }}>
                <Form.Item label="Select a date">
                  <DatePicker
                    className="mr-m"
                    size="large"
                    format="DD/MM/YYYY"
                    defaultValue={moment(new Date(), "DD/MM/YYYY")}
                    disabledDate={disabledDate}
                    onChange={(date) => {
                      setUnavailableDay(date);
                    }}
                  />
                </Form.Item>
              </Form>
            </Col>
            <Col>
              <Button
                className="a-button v-button-primary"
                onClick={handleAddDateClick}>
                Add unavailable date
              </Button>
            </Col>
          </Row>

          <Table
            className="no-hover mb-m"
            loading={isUnavailableListLoading}
            rowKey={(record) => record.id}
            pagination={false}
            dataSource={unavailableDates}
            columns={columns}
          />
        </Layout.Content>

        {skipperId ? (
          <Layout.Content>
            <Link to="/dashboard/skippers">
              <Button className="a-button v-button-primary">Cancel</Button>
            </Link>
          </Layout.Content>
        ) : null}
      </Layout>
    </>
  );
}

export default DashboardAvailablity;
