import React, { useState } from "react";
import { Layout, Row, Col, Button, Select } from "antd";
import Report from "../../../enums/ReportType";
import * as WetwheelsApi from "../../../client-swagger/api";
import { useApp } from "../../../AppContext";

function DashboardReports() {
  const [futureReport, setFutureReport] = useState<Report>(Report.None);
  const [pastReport, setPastReport] = useState<Report>(Report.None);
  const { authHeaderOptions } = useApp();
  const handleFutureReportChange = (value: Report) => {
    setFutureReport(value);
  };
  const handlePastReportChange = (value: Report) => {
    setPastReport(value);
  };

  const downloadReport = async (report: Report, isFuture: boolean) => {
    const rType = enumText(report);
    const {
      success,
      message,
      data,
    } = await new WetwheelsApi.ReportApi().reportBookingsTimeFrameBookingStatusGet(
      isFuture ? "Future" : "Past",
      rType,
      authHeaderOptions
    );
    if (success) {
      downloadCsv(data, report, isFuture, message);
    }
  };

  const handleExportFuture = async () => {
    await downloadReport(futureReport, true);
  };

  const handleExportPast = async () => {
    await downloadReport(pastReport, false);
  };

  const enumText = (report: Report) => {
    const reportText = Report[report];
    return reportText;
  };

  const downloadCsv = (
    csv: string,
    report: Report,
    isFuture: boolean,
    fileName: string
  ) => {
    const linkSource = `data:text/csv;base64,${csv}`;
    const downloadLink = document.createElement("a");

    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  };

  return (
    <>
      <Layout>
        <Layout.Header
          className="mb-m"
          style={{ padding: 0, background: "none", width: "100%" }}>
          <h2>Reports</h2>
        </Layout.Header>
        <Layout.Content>
          <Row gutter={[16, 16]}>
            <Col md={{ span: 24 }}>
              <h3>Future bookings</h3>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col md={{ span: 12 }}>
              <Select
                defaultValue={Report.None}
                style={{ width: "100%" }}
                onChange={handleFutureReportChange}>
                <Select.Option value={Report.None}>
                  -- Please select below --
                </Select.Option>
                <Select.Option value={Report.All}>All</Select.Option>
                <Select.Option value={Report.Confirmed}>
                  Confirmed
                </Select.Option>
                <Select.Option value={Report.Unconfirmed}>
                  Unconfirmed
                </Select.Option>
                <Select.Option value={Report.Cancelled}>
                  Cancelled
                </Select.Option>
              </Select>
            </Col>
          </Row>
          <Row gutter={[16, 52]}>
            <Col md={{ span: 24 }}>
              <Button
                className="a-button v-button-primary"
                disabled={futureReport === Report.None}
                onClick={handleExportFuture}>
                Download
              </Button>
            </Col>
          </Row>

          <Row gutter={[16, 16]}>
            <Col md={{ span: 24 }}>
              <h3>Past bookings</h3>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col md={{ span: 12 }}>
              <Select
                defaultValue={Report.None}
                style={{ width: "100%" }}
                onChange={handlePastReportChange}>
                <Select.Option value={Report.None}>
                  -- Please select below --
                </Select.Option>
                <Select.Option value={Report.All}>All</Select.Option>
                <Select.Option value={Report.Confirmed}>
                  Confirmed
                </Select.Option>
                <Select.Option value={Report.Unconfirmed}>
                  Unconfirmed
                </Select.Option>
                <Select.Option value={Report.Cancelled}>
                  Cancelled
                </Select.Option>
              </Select>
            </Col>
          </Row>
          <Row gutter={[16, 52]}>
            <Col md={{ span: 24 }}>
              <Button
                className="a-button v-button-primary"
                disabled={pastReport === Report.None}
                onClick={handleExportPast}>
                Download
              </Button>
            </Col>
          </Row>
        </Layout.Content>
      </Layout>
    </>
  );
}

export default DashboardReports;
