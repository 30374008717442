import React from "react";
import { Form, Select, Alert, Collapse, Timeline, Button } from "antd";
import { useApp } from "../../../../AppContext";
import User from "../../../../enums/User";

interface ISkipperCrewAudit {
  bookingData: any;
  skippersOrCrew: any;
  fetchBookingData: (value: any) => void;
  saveSkipperOrCrew: () => void;
  skipperOrCrew: string;
}

const SkipperOrCrewAudit = ({
  bookingData,
  skippersOrCrew,
  saveSkipperOrCrew,
  skipperOrCrew,
}: ISkipperCrewAudit) => {
  const { userType } = useApp();

  const isSkipper = skipperOrCrew === "Skipper";

  const { Panel } = Collapse;

  return (
    <div>
      {userType === User.Admin && isSkipper !== null && (
        <>
          <Form.Item label={" "} colon={false}>
            <Alert
              type="info"
              message={
                isSkipper
                  ? bookingData.skipper
                    ? "Changing the Skipper and clicking 'Update Skipper' will change the skipper assigned"
                    : "Select a Skipper and click 'Update Skipper'. The booking will then be confirmed"
                  : bookingData.crew
                  ? `Changing the Crew and clicking 'Update Crew' will change the crew assigned`
                  : `Select a Crew Member and click 'Update Crew'. The booking will then be confirmed`
              }
            />
          </Form.Item>
          <Form.Item
            label={skipperOrCrew}
            name={`${skipperOrCrew}Id`}
            initialValue={
              isSkipper ? bookingData.skipperId : bookingData.crewId
            }>
            <Select
              size="large"
              placeholder={
                isSkipper
                  ? bookingData.skipper
                    ? null
                    : "No skipper assigned"
                  : bookingData.crew
                  ? null
                  : "No crew assigned"
              }>
              <>
                <Select.Option value="none" key="none">
                  None
                </Select.Option>
                {skippersOrCrew &&
                  skippersOrCrew.map((skipperOrCrewMember: any) => {
                    return (
                      <Select.Option
                        value={skipperOrCrewMember.id}
                        key={
                          skipperOrCrewMember.id
                        }>{`${skipperOrCrewMember.user.firstName} ${skipperOrCrewMember.user.lastName}`}</Select.Option>
                    );
                  })}
              </>
            </Select>
          </Form.Item>
          <Form.Item label={" "} colon={false}>
            <Button onClick={saveSkipperOrCrew} type="primary">
              Update {skipperOrCrew}
            </Button>
          </Form.Item>
        </>
      )}
      {(isSkipper ? bookingData.skipperAudit : bookingData.crewAudit) &&
      userType === User.Admin &&
      (isSkipper
        ? bookingData.skipperAudit.length
        : bookingData.crewAudit.length) ? (
        <Collapse
          bordered={false}
          expandIconPosition="left"
          defaultActiveKey="1"
          className="mb-m v-collapse-no-style">
          <Panel header={`${skipperOrCrew} decisions`} key="1">
            <Timeline>
              {(isSkipper
                ? bookingData.skipperAudit
                : bookingData.crewAudit
              ).map((audit: any, index: any) => {
                return (
                  <Timeline.Item
                    key={`timeline-${index}`}
                    color={audit.isAccepted ? "green" : "red"}>
                    {audit.description}
                  </Timeline.Item>
                );
              })}
            </Timeline>
          </Panel>
        </Collapse>
      ) : null}
    </div>
  );
};

export default SkipperOrCrewAudit;
