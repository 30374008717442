import React from "react";
import { Row, Col } from "antd";

const CardRow = ({ label, value, leftSize }: ICardRowProps) => {
  if (!leftSize) {
    leftSize = 15;
  }
  return (
    <Row gutter={16}>
      <Col span={leftSize}>{label}</Col>
      <Col span={24 - leftSize}>{value}</Col>
    </Row>
  );
};

export default CardRow;

export interface ICardRowProps {
  label: string;
  value: string;
  leftSize?: number;
}
