import React from "react";
import { Alert, Row, Col } from "antd";
import { useApp } from "../../../../AppContext";
import User from "../../../../enums/User";
import ManifestPassengerCard from "./manifestPassengerCard";
import ManifestAlert from "./manifestAlert";
import Disclaimer from "./disclaimer";

export interface IManifestPassengerListProps {
  bookingData: any;
}

const ManifestPassengerList = ({
  bookingData,
}: IManifestPassengerListProps) => {
  const { id } = bookingData;
  const { userType } = useApp();
  const isAdminOrSkipper = userType === User.Skipper || userType === User.Admin;

  return bookingData.manifest ? (
    <>
      <Row className="mb-m" gutter={16}>
        <Col span={24}>
          <h3>Manifest</h3>
        </Col>

        {bookingData.manifest.passengers ? (
          <>
            <Row className="mb-m mt-m" gutter={16}>
              <Col span={24}>
                <ManifestPassengerCard bookingData={bookingData} />
              </Col>
              <Col span={24}>
                {bookingData.manifest.passengers.length <
                bookingData.numberOfPassengers ? (
                  <Alert
                    style={{ width: "100%" }}
                    className="mb-l"
                    message={`The booking specifies ${bookingData.numberOfPassengers} passengers but the manifest only contains details for ${bookingData.manifest.passengers.length} passenger(s)`}
                    type="warning"></Alert>
                ) : bookingData.manifest.passengers.length ===
                  bookingData.numberOfPassengers ? (
                  <Alert
                    style={{ width: "100%" }}
                    className="mb-l"
                    message="All passenger details have been completed"
                    type="success"></Alert>
                ) : (
                  <Alert
                    style={{ width: "100%" }}
                    className="mb-l"
                    message={`The booking specifies ${
                      bookingData.numberOfPassengers
                    } passengers but the manifest contains details for ${
                      bookingData.manifest.passengers.length
                    } passenger(s). Please remove  ${
                      bookingData.manifest.passengers.length -
                      bookingData.numberOfPassengers
                    } passenger(s)`}
                    type="warning"></Alert>
                )}
              </Col>
              <Col span={24}>
                <ManifestAlert
                  message="The manifest must be accurate before departure"
                  button="Amend now"
                  id={id}
                  type="info"
                  page="manifest"
                />
              </Col>
            </Row>
          </>
        ) : (
          <ManifestAlert
            message="The manifest must be completed before departure"
            button="Complete now"
            id={id}
            type="error"
            page="manifest"
          />
        )}
      </Row>

      <Row className="mb-m" gutter={16}>
        <Col span={24}>
          <h3>Disclaimer</h3>
        </Col>
      </Row>

      {bookingData.disclaimerSignedDate ? (
        <Disclaimer bookingData={bookingData} />
      ) : (
        <ManifestAlert
          message="Disclaimer must be completed before departure:"
          button="Complete now"
          id={id}
          type="error"
          page="disclaimer"
        />
      )}
    </>
  ) : (
    <Row
      className="mb-m"
      justify="space-between"
      gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
      <Col span={24}>
        <h3>Manifest</h3>
      </Col>
      <ManifestAlert
        message={
          isAdminOrSkipper
            ? "The client has not yet completed their manifest. You can do it for them if you wish."
            : "You have not completed your manifest"
        }
        id={id}
        button="Complete now"
        type="info"
        page="manifest"
      />

      <Col span={24}>
        <p>
          The disclaimer has not been signed. The manifest must be completed
          first.
        </p>
      </Col>
    </Row>
  );
};

export default ManifestPassengerList;
