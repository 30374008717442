import React from "react";

import { Descriptions } from "antd";
import { useApp } from "../../AppContext";
import moment from "moment";

const BookingSummary = () => {
  const { bookingModel, userName } = useApp();

  const timeStart = bookingModel.timeStart
    ? moment(bookingModel.timeStart).format("dddd, MMMM Do YYYY")
    : null;

  return bookingModel ? (
    <>
      <Descriptions
        title="Booking Information"
        layout="vertical"
        bordered
        column={{ xs: 8, sm: 16, md: 24 }}
        className="mt-m mb-m"
      >
        <Descriptions.Item label="Activity" span={12}>
          {bookingModel.activityType}
        </Descriptions.Item>
        <Descriptions.Item label="Full name" span={12}>
          {userName}
        </Descriptions.Item>
        <Descriptions.Item label="Booking date" span={12}>
          {timeStart}
        </Descriptions.Item>
        <Descriptions.Item label="Start time" span={12}>
          {moment(bookingModel.timeStart)?.format("LT")}
        </Descriptions.Item>
        {bookingModel.timeEnd && (
          <>
            <Descriptions.Item label="End time" span={12}>
              {moment(bookingModel.timeEnd)?.format("LT")}
            </Descriptions.Item>
          </>
        )}

        <Descriptions.Item label="Number of passengers" span={12}>
          {bookingModel.numberOfPassengers}
        </Descriptions.Item>
        <Descriptions.Item label="Number of wheelchair passengers" span={12}>
          {bookingModel.numberOfWheelChairs}
        </Descriptions.Item>
        <Descriptions.Item label="Number of special needs passengers" span={12}>
          {bookingModel.numberOfNonWheelchairDisabled}
        </Descriptions.Item>

        {bookingModel.additionalInformation && (
          <>
            <Descriptions.Item label="Additional information" span={24}>
              {bookingModel.additionalInformation}
            </Descriptions.Item>
          </>
        )}
      </Descriptions>
    </>
  ) : (
    <p>No booking?</p>
  );
};

export default BookingSummary;
