import React, { useState, useEffect } from "react";
import { Layout, Drawer, Button, Row, Col } from "antd";
import DashboardSkipperDetail from "./dashboardSkipperDetail";

import * as WetwheelsApi from "../../../client-swagger/api";
import { useApp } from "../../../AppContext";
import { Link } from "react-router-dom";
import AuthHeaderOptions from "../../../AuthHeaderOptions";
import { IDayAvailability } from "../Availability/dashboardAvailablity";
import Availability from "../../../enums/Availability";
import { Breakpoint } from "antd/lib/_util/responsiveObserve";
import DashboardSkipperAdd from "./dashboardSkipperAdd";
import UserList from "../../../components/User/List/userList";

function DashboardSkippers() {
  const { jwtToken, notifyError } = useApp();
  const [list, setList] = useState();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [data, setData] = useState(null);
  const [dayAvailability, setDayAvailability] = useState<IDayAvailability[]>(
    []
  );
  const [drawerVisible, setDrawerVisible] = useState<boolean>(false);
  const [drawerAddVisible, setDrawerAddVisible] = useState<boolean>(false);
  let disable = false;

  const authHeaderOptions = AuthHeaderOptions(jwtToken);

  const fetchSkipperData = (skipperId: string) => {
    new WetwheelsApi.SkipperApi()
      .skipperGet(skipperId, authHeaderOptions)
      .then(({ data, success, message }) => {
        if (!success) {
          notifyError(message);
        }

        setData(data);

        const availabilityLoaded: IDayAvailability[] = [
          { dayName: "Monday", availability: data.monday as Availability },
          { dayName: "Tuesday", availability: data.tuesday as Availability },
          {
            dayName: "Wednesday",
            availability: data.wednesday as Availability,
          },
          { dayName: "Thursday", availability: data.thursday as Availability },
          { dayName: "Friday", availability: data.friday as Availability },
          { dayName: "Saturday", availability: data.saturday as Availability },
          { dayName: "Sunday", availability: data.sunday as Availability },
        ];

        setDayAvailability(availabilityLoaded);
      });
  };

  const handleRow = (record: any) => {
    return {
      onClick: () => {
        if (disable) return;
        fetchSkipperData(record.skipperId);
        setDrawerVisible(true);
      },
    };
  };

  const onClose = () => {
    setData(null);
    setDrawerVisible(false);
    setDrawerAddVisible(false);
    fetchList();
  };

  const handleNewSkipperClick = () => {
    setDrawerAddVisible(true);
  };

  const fetchList = async () => {
    setIsLoading(true);

    const {
      data,
      success,
      message,
    } = await new WetwheelsApi.SkipperApi().skippersGet(authHeaderOptions);

    setIsLoading(false);

    if (!success) {
      notifyError(message);
    }
    setList(
      data.map((s: any) => {
        let user = s.user;
        user.skipperId = s.id;
        return user;
      })
    );
  };

  useEffect(() => {
    const getData = async () => {
      await fetchList();
    };

    getData();
  }, []);

  return list ? (
    <>
      <Layout>
        <Layout.Header
          className="mb-m v-ant-layout-header"
          style={{ padding: 0, background: "none", width: "100%" }}>
          <Row justify="space-between" align="middle">
            <Col>
              <h2 className="mr-m">Skippers</h2>
            </Col>
            <Col>
              <button
                className="a-button v-button-primary"
                onClick={handleNewSkipperClick}>
                Add new skipper
              </button>
            </Col>
          </Row>
        </Layout.Header>
        <Layout.Content>
          <UserList
            bindData={fetchList}
            handleRowClick={handleRow}
            isLoading={isLoading}
            list={list}
            additionalColumns={[
              {
                title: "Availability",
                key: "action",
                responsive: ["lg"] as Breakpoint[],
                render: (text: any, record: any) => (
                  <Button size="middle">
                    <Link to={`/dashboard/availability/${record.skipperId}`}>
                      Availability
                    </Link>
                  </Button>
                ),
                width: "180px",
              },
            ]}></UserList>
        </Layout.Content>
      </Layout>
      <Drawer
        width={"75vw"}
        onClose={onClose}
        visible={drawerVisible}
        bodyStyle={{ paddingBottom: 80 }}>
        <DashboardSkipperDetail
          closeWindow={onClose}
          skipperData={data}
          dayAvailability={dayAvailability}
        />
      </Drawer>
      <Drawer
        width={"75vw"}
        onClose={onClose}
        visible={drawerAddVisible}
        bodyStyle={{ paddingBottom: 80 }}>
        <DashboardSkipperAdd closeWindow={onClose} />
      </Drawer>
    </>
  ) : null;
}

export default DashboardSkippers;
