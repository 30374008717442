import React from "react";
import { Route, Switch, BrowserRouter } from "react-router-dom";
import MainLayout from "./components/MainLayout";
import Login from "./Views/Login";
import Register from "./Views/Login/Register/register";
import "antd/dist/antd.css";
import "./styles/main.scss";
import ForgotPassword from "./Views/Login/ForgotPassword";
import DashboardRoute from "./Views/Dashboard/dashboardRoute";
import HomeRouter from "./Views/Home/homeRouter";
import ReviewBooking from "./Views/Home/reviewBooking";
import ConfirmBooking from "./Views/Home/confirmBooking";
import { BookingProvider } from "./components/Booking/bookingContext";
import RegisterEmail from "./Views/Login/Register/RegisterEmail";
import ConfirmEmail from "./Views/Login/confirmEmail";
import ForgotPasswordReset from "./Views/Login/ForgotPassword/reset";
import PrivacyPolicy from "./components/PrivacyPolicy";
import LegalDisclaimer from "./components/LegalDisclaimer";
import ManifestPrintRoute from "./Views/Dashboard/manifestPrintRoute";

import PrivateRoute from "./components/Util/PrivateRoute";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { faCheckSquare, faCoffee } from "@fortawesome/free-solid-svg-icons";
import Temp from "./Views/Login/Register/temp";

library.add(fab, faCheckSquare, faCoffee);

function App() {
  return (
    <BrowserRouter>
      <Switch>
        <PrivateRoute path="/dashboard" component={DashboardRoute} />
        <PrivateRoute path="/print" component={ManifestPrintRoute} />

        <MainLayout>
          <Route
            exact
            path="/login/forgotPassword"
            component={ForgotPassword}
          />
          <Route exact path="/login/register" component={Temp} />
          <Route exact path="/login/register/email" component={RegisterEmail} />
          <Route exact path="/login/confirm/:userId" component={ConfirmEmail} />
          <Route
            exact
            path="/login/resetpassword/:resetKey"
            component={ForgotPasswordReset}
          />
          <Route exact path="/login" component={Login} />
          <BookingProvider>
            <PrivateRoute
              exact
              path="/booking/review"
              component={ReviewBooking}
            />
            {/* <Route exact path={`/booking/review`} component={ReviewBooking} /> */}
            <Route exact path={`/booking/confirm`} component={ConfirmBooking} />
            <Route exact path="/" component={HomeRouter} />
            <Route exact path={`/privacy`} component={PrivacyPolicy} />
            <Route exact path={`/legal`} component={LegalDisclaimer} />
          </BookingProvider>
        </MainLayout>
      </Switch>
    </BrowserRouter>
  );
}

export default App;
